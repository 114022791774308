<template>
    <div>
      <AppHeader ref="appHeader">
          <template v-slot:bg>
              <div class="page-header-1-figure m-parallax-figure">
              <div class="page-header-1-image m-parallax-image" style="background-image: url(images/bg-image-13.jpg);"></div>
              </div>
          </template>
          <template v-slot:customFormItem>
            <section class="breadcrumbs-custom">
          <div class="breadcrumbs-custom-inner">
            <div class="container">
              <div class="breadcrumbs-custom-main m-parallax-content">
                <div class="heading-1 heading-promo text-decorative-white">
                <div class="big">{{$t('thank')}}</div>
                </div>
                <h2 class="breadcrumbs-custom-title">{{$t('thank')}}</h2>
                <p>{{ $t('buy_title') }}</p>
              </div>
            </div>
          </div>
        </section>
          </template>
          <template v-slot:sectionItem>
            <section class="section section-md bg-default text-center">
                <div class="container">
                <!-- <article class="text-group-1">
                    <p class="text-xxl text-outer">404</p>
                    <h4 class="text-inner">Oops! That page can't be found</h4>
                </article> -->
                <!-- RD Search-->
                <form class="rd-search rd-search-inline block-2 block-centered mt-lg-60" action="search-results.html" method="GET">
                    <div class="form-wrap">
                    <label class="form-label" for="rd-search-form-input">{{$t('search')}}...</label>
                    <input class="form-input" id="rd-search-form-input" type="text" name="s" autocomplete="off">
                    </div>
                    <div class="form-button">
                    <button class="rd-search-submit" type="submit"></button>
                    </div>
                </form>
                </div>
            </section>
          </template>
        </AppHeader>
  
      <AppFooter />
    </div>
  </template>
  
  <script>
  import AppHeader from '../components/AppHeader.vue';
  import AppFooter from '../components/AppFooter.vue';
  
  export default {
    components: {
      AppHeader,
      AppFooter
    },
    created() {
      
    },
    mounted(){
      this.$refs.appHeader.handlePageTransition();
    }
  };
  </script>
  