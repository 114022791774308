import { createApp } from 'vue';
import App from './App.vue';
import { createMetaManager } from 'vue-meta';
import $ from 'jquery'; // 引入 jQuery
import router from './router'; // 引入 router
import axios from 'axios';

import ElementPlus from 'element-plus';
import 'element-plus/dist/index.css';
import i18n from './i18n';
// 全局引入 CSS 文件
import '@/assets/css/bootstrap.css';
import '@/assets/css/fonts.css';
import '@/assets/css/style.css';

// 创建应用
const app = createApp(App);

// 创建 MetaManager 并使用
const metaManager = createMetaManager();
// 使用 Element Plus
app.use(ElementPlus);
app.use(metaManager);
app.use(router);
app.use(i18n); // 使用 i18n 插件

// 全局变量
const $document = $(document);
const $window = $(window);
const $html = $("html");
const $body = $("body");
// 设置 axios 的默认 baseURL
// axios.defaults.baseURL = 'http://dev.car.test.com/api';
axios.defaults.baseURL = '//car-api.sohomotorclub.com/api';

// 将全局变量添加到应用上下文
app.config.globalProperties.$document = $document;
app.config.globalProperties.$window = $window;
app.config.globalProperties.$html = $html;
app.config.globalProperties.$body = $body;

app.mount('#app');
