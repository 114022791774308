<template>
  <div>
    <AppHeader ref="appHeader">
        <template v-slot:bg>
            <div class="page-header-1-figure m-parallax-figure">
            <div class="page-header-1-image m-parallax-image" style="background-image: url(images/bg-image-1.jpg);"></div>
            </div>
        </template>
        <template v-slot:customFormItem>
            <section class="breadcrumbs-custom">
            <div class="breadcrumbs-custom-inner">
              <div class="container">
                <div class="breadcrumbs-custom-main m-parallax-content">
                  <h2 class="breadcrumbs-custom-title">{{$t('about')}}</h2>
                  <p>{{ $t('buy_title') }}</p>
                </div>
              </div>
            </div>
            </section>
        </template>
        <template v-slot:sectionItem>
            <!-- Basic Info-->
            <section class="section section-sm bg-default">
              <div class="container">
                <div class="row row-50 justify-content-center align-items-center">
                  <div class="col-md-10 col-lg-7">
                    <h3 class="block-1">{{ $t('about_title') }}</h3>
                    <div class="block-9 mt-20">
                      <p> {{ $t('about_content1') }}</p>
                      <p v-if="readMore">{{ $t('about_content2') }}</p>
                      <p v-if="readMore">{{ $t('about_content3') }}</p>
                    </div><a v-if="!readMore" class="button button-lg button-primary mt-lg-40" href="javascript:void(0);" @click="handleReadMore">{{$t('read')}}</a>
                  </div>
                  <div class="col-md-10 col-lg-5">
                    <figure class="figure-1" role="presentation"><img src="images/about-1-506x368.jpg" alt="" width="506" height="368"/>
                    </figure>
                  </div>
                </div>
              </div>
            </section>
            <!-- Features-->
            <section class="section section-md bg-gray-100">
              <div class="container">
                <div class="row row-50 list-group-1">
                  <div class="col-md-6 col-lg-3">
                    <article class="lg-1-item wow fadeIn">
                      <div class="icon lg-1-item-icon thin-icon-checklist"></div>
                      <div class="lg-1-item-main">
                        <h3 class="lg-1-item-title"><span class="lg-1-item-counter"></span>{{ $t('professionalism') }}</h3>
                        <p>{{ $t('professionalism_tips') }}</p>
                      </div>
                    </article>
                  </div>
                  <div class="col-md-6 col-lg-3">
                    <article class="lg-1-item wow fadeIn" data-wow-delay=".025s">
                      <div class="icon lg-1-item-icon thin-icon-mobile"></div>
                      <div class="lg-1-item-main">
                        <h3 class="lg-1-item-title"><span class="lg-1-item-counter"></span>{{  $t('support') }}</h3>
                        <p>{{ $t('support_title') }}</p>
                      </div>
                    </article>
                  </div>
                  <div class="col-md-6 col-lg-3">
                    <article class="lg-1-item wow fadeIn" data-wow-delay=".05s">
                      <div class="icon lg-1-item-icon thin-icon-chat"></div>
                      <div class="lg-1-item-main">
                        <h3 class="lg-1-item-title"><span class="lg-1-item-counter"></span>{{ $t('affordability') }}</h3>
                        <p>{{ $t('affordability_title') }}</p>
                      </div>
                    </article>
                  </div>
                  <div class="col-md-6 col-lg-3">
                    <article class="lg-1-item wow fadeIn" data-wow-delay=".05s">
                      <div class="icon lg-1-item-icon thin-icon-email-search"></div>
                      <div class="lg-1-item-main">
                        <h3 class="lg-1-item-title"><span class="lg-1-item-counter"></span>{{ $t('regular_checks') }}</h3>
                        <p>{{ $t('regular_checks_title') }}</p>
                      </div>
                    </article>
                  </div>
                </div>
              </div>
            </section>
            <!-- Our Team-->
            <section class="section bg-color-default">
              <div class="range"> 
                <div class="cell-lg-7 cell-xl-8 cell-xxl-9">
                  <div class="cell-inner section-lg text-center text-sm-left">
                    <h3>Meet Our Team</h3>
                    <p>Duis aute irure dolor in reprehenderit in voluptate velit</p>
                    <!-- Owl Carousel-->
                    <div class="owl-carousel owl-1 mt-lg-50" data-items="1" data-sm-items="2" data-md-items="3" data-lg-items="2" data-xl-items="3" data-dots="true" data-nav="false" data-stage-padding="0" data-loop="false" data-margin="30" data-mouse-drag="false">
                      <!-- Profile Classic-->
                      <article class="profile-classic"><img class="profile-classic-image" src="images/our-team-1-272x197.jpg" alt="" width="272" height="197"/>
                        <div class="profile-classic-main">
                          <p class="heading-5 profile-classic-position">CEO, Founder</p>
                          <p class="profile-classic-name heading-4">Peter Jones</p>
                          <div class="profile-classic-unit">
                            <div class="icon mdi mdi-phone"></div><a class="heading-6" href="tel:#">1-800-36-677</a>
                          </div>
                        </div>
                      </article>
                      <!-- Profile Classic-->
                      <article class="profile-classic"><img class="profile-classic-image" src="images/our-team-2-272x197.jpg" alt="" width="272" height="197"/>
                        <div class="profile-classic-main">
                          <p class="heading-5 profile-classic-position">Service Manager</p>
                          <p class="profile-classic-name heading-4">Peter Jones</p>
                          <div class="profile-classic-unit">
                            <div class="icon mdi mdi-phone"></div><a class="heading-6" href="tel:#">1-800-36-677</a>
                          </div>
                        </div>
                      </article>
                      <!-- Profile Classic-->
                      <article class="profile-classic"><img class="profile-classic-image" src="images/our-team-3-272x197.jpg" alt="" width="272" height="197"/>
                        <div class="profile-classic-main">
                          <p class="heading-5 profile-classic-position">Consultant</p>
                          <p class="profile-classic-name heading-4">Elizabeth Smith</p>
                          <div class="profile-classic-unit">
                            <div class="icon mdi mdi-phone"></div><a class="heading-6" href="tel:#">1-800-36-677</a>
                          </div>
                        </div>
                      </article>
                    </div>
                  </div>
                </div>
                <div class="cell-lg-5 cell-xl-4 cell-xxl-3 height-fill">
                  <div class="box-3 bg-image" style="background-image: url(images/about-2.jpg);"><a class="box-3-play" data-lightgallery="item" href="#"><span class="icon mdi mdi-play"></span></a></div>
                </div>
              </div>
            </section>
            <!-- Partners -->
            <section class="section section-lg bg-gray-100">
              <div class="container">
                <div class="box-9-group-outer">
                <div class="box-9-group">
                  <div v-for="(brand, index) in carBrands" :key="index" class="box-9-item wow fadeIn" data-wow-delay=".1s">
                    <router-link :to="`/buy?brand=${brand.name}`" class="box-9-link" data-class="tooltip-light" data-toggle="tooltip" data-placement="top" :title="brand.name">
                      <div class="box-9-link-inner">
                        <img :src="brand.image" alt="" width="48" height="48"/>
                      </div>
                      <p class="box-9-title">{{ brand.name }}</p>  
                    </router-link>
                  </div>
                </div>
              </div>
              </div>
            </section>
        </template>
      </AppHeader>

    <AppFooter />
  </div>
</template>

<script>
import AppHeader from '../components/AppHeader.vue';
import AppFooter from '../components/AppFooter.vue';
// import http from '../http'; // 引入 Axios 实例
import axios from 'axios';

export default {
  components: {
    AppHeader,
    AppFooter
  },
  data() {
    return {
       readMore: false,
       carBrands: [],
    }
  },
  methods: {
    handleReadMore(){
      this.readMore = true;
    },
    async fetchCar() {
      try {
            axios.get(`/cars`).then((response) => {
              this.carBrands = response.data.brand;
              this.$refs.appHeader.handlePageTransition();
            }); 
      } catch (err) {
        console.error('获取车辆数据失败:', err);
      } finally {
        console.error('完成');
      }
    },
  },
  created() {
    
  },
  mounted(){
    this.fetchCar();
    // this.$refs.appHeader.handlePageTransition();
  }
};
</script>
