<template>
  <div class="ie-panel"><a href="http://windows.microsoft.com/en-US/internet-explorer/"><img src="images/ie8-panel/warning_bar_0000_us.jpg" height="42" width="820" alt="You are using an outdated browser. For a faster, safer browsing experience, upgrade for free today."></a></div>
  <!-- <div class="preloader">
    <div class="preloader-body">
      <div class="cssload-container">
        <div class="cssload-speeding-wheel"></div>
      </div>
      <p>Loading...</p>
    </div>
  </div> -->
  <div class="page">
  <header class="section page-header page-header-1 context-dark m-parallax">
    <slot name="bg"></slot>
    <div class="rd-navbar-wrap">
      <nav class="rd-navbar rd-navbar-classic" data-layout="rd-navbar-fixed" data-sm-layout="rd-navbar-fixed" data-md-layout="rd-navbar-fixed" data-md-device-layout="rd-navbar-fixed" data-lg-layout="rd-navbar-static" data-lg-device-layout="rd-navbar-fixed" data-xl-layout="rd-navbar-static" data-xl-device-layout="rd-navbar-static" data-lg-stick-up-offset="1px" data-xl-stick-up-offset="1px" data-xxl-stick-up-offset="1px" data-lg-stick-up="true" data-xl-stick-up="true" data-xxl-stick-up="true">
        <div class="rd-navbar-main-outer">
          <div class="rd-navbar-main">
            <div class="rd-navbar-panel">
              <button class="rd-navbar-toggle" data-rd-navbar-toggle=".rd-navbar-nav-wrap"><span></span></button>
              <div class="rd-navbar-brand">
                  <router-link class="brand" to="/home">
                    <img class="brand-logo-dark" src="images/1729757984246_1.png" alt="" width="141" height="29"/>
                    <img class="brand-logo-light" src="images/1729757984246_1.png" alt="" width="141" height="29"/>
                  </router-link>
              </div>
            </div>
            <div class="rd-navbar-nav-wrap">
              <ul class="rd-navbar-nav">
                <li :class="{'rd-nav-item': true, 'active': isActive('/') }">
                  <router-link class="rd-nav-link" to="/home">{{ $t('home') }}</router-link>
                </li>
                <li :class="{'rd-nav-item': true, 'active': isActive('/buy') }">
                  <router-link class="rd-nav-link" to="/buy">{{ $t('buy') }}</router-link>
                </li>
                <li :class="{'rd-nav-item': true, 'active': isActive('/about') }">
                  <router-link class="rd-nav-link" to="/about">{{ $t('about') }}</router-link>
                </li>
                <li :class="{'rd-nav-item': true, 'active': isActive('/contact') }">
                  <router-link class="rd-nav-link" to="/contact">{{ $t('contact') }}</router-link>
                </li>
              </ul>
            </div>
            <div class="context-light hidden"><a class="button button-white-40-outline button-mobile" href="#">add car</a></div>
          </div>
        </div>
        <div class="rd-navbar-placeholder"></div>
      </nav>
    </div>
    <slot name="customFormItem"></slot>
  </header>
  <slot name="sectionItem"></slot>
  
  </div>
  <div class="snackbars" id="form-output-global"></div>
</template>

<script>

export default {
  name: "AppHeader",
  data() {
    return {
      preloaderLoaded: false, // 控制是否已经加载过预加载器
      userAgent: navigator.userAgent.toLowerCase(),
      initialDate: new Date(),
      isDesktop: false,
      isIE: false,
      isMobile: false,
      windowReady: false,
      isNoviBuilder: false,
      livedemo: false,
      plugins: {
        bootstrapTooltip: null,
        bootstrapModal: null,
        bootstrapTabs: null,
        counter: null,
        captcha: null,
        campaignMonitor: null,
        copyrightYear: null,
        checkbox: null,
        lightGallery: null,
        lightGalleryItem: null,
        lightDynamicGalleryItem: null,
        materialParallax: null,
        mailchimp: null,
        owl: null,
        swiper: null,
        progressLinear: null,
        preloader: null,
        selectFilter: null,
        rdNavbar: null,
        rdMailForm: null,
        rdInputLabel: null,
        regula: null,
        search: null,
        searchResults: null,
        wow: null,
        maps: null,
        countDown: null,
        multitoggles: null,
        vide: null,
        rdRange: null,
        focusToggle: null,
        waypoint: null,
        mParallax: null,
        slick: null
      }
    }
  },
    metaInfo: {
    title: 'Cars 1',
    meta: [
      { charset: 'utf-8' },
      { name: 'viewport', content: 'width=device-width, height=device-height, initial-scale=1.0' },
      { 'http-equiv': 'X-UA-Compatible', content: 'IE=edge' }
    ],
    link: [
      { rel: 'icon', href: '/favicon.ico', type: 'image/x-icon' },
      { rel: 'stylesheet', href: 'https://fonts.googleapis.com/css?family=Roboto:300,400,500,700,900%7CBaloo' },
      // 注意：全局 CSS 文件已经在 main.js 中引入，这里不需要再次引入
    ],
      style: [
        { cssText: '.ie-panel{display: none;background: #212121;padding: 10px 0;box-shadow: 3px 3px 5px 0 rgba(0,0,0,.3);clear: both;text-align:center;position: relative;z-index: 1;} html.ie-10 .ie-panel, html.lt-ie-10 .ie-panel {display: block;}', type: 'text/css' }
      ]
    },
    methods :{
        // 检查当前路由是否匹配
        isActive(route) {
            return this.$route.path === route;
        },
        /**
       * @desc Check if the element has been scrolled into the view
       * @param {object} elem - jQuery object
       * @return {boolean}
       */
        isScrolledIntoView(elem) {
          if (this.isNoviBuilder) return true;
          return elem.offset().top + elem.outerHeight() >= this.$window.scrollTop() && elem.offset().top <= this.$window.scrollTop() + this.$window.height();
        },
  
        lazyInit(element, func){
          var scrollHandler = () => {
            if ( ( !element.hasClass( 'lazy-loaded' ) && ( this.isScrolledIntoView( element ) ) ) ) {
              func.call();
              element.addClass( 'lazy-loaded' );
            }
          };
          scrollHandler();
          this.$window.on( 'scroll', scrollHandler );
        },
  
        detectIE() {
          const ua = this.userAgent;
          const msie = ua.indexOf("msie");
          const trident = ua.indexOf("trident");
          const edge = ua.indexOf("edge");
          if (msie !== -1) {
            return parseInt(ua.split("msie")[1], 10);
          } else if (trident !== -1) {
            return 11;
          } else if (edge !== -1) {
            return 12;
          }
          return false;
        },
        initPlugins() {
          this.plugins.bootstrapTooltip = $("[data-toggle='tooltip']");
          this.plugins.bootstrapModal = $('.modal');
          this.plugins.bootstrapTabs = $('.tabs-custom');
          this.plugins.counter = $('.counter');
          this.plugins.captcha = $('.recaptcha');
          this.plugins.campaignMonitor = $('.campaign-mailform');
          this.plugins.copyrightYear = $('.copyright-year');
          this.plugins.checkbox = $("input[type='checkbox']");
          this.plugins.lightGallery = $("[data-lightgallery='group']");
          this.plugins.lightGalleryItem = $("[data-lightgallery='item']");
          this.plugins.lightDynamicGalleryItem = $("[data-lightgallery='dynamic']");
          this.plugins.materialParallax = $('.parallax-container');
          this.plugins.mailchimp = $('.mailchimp-mailform');
          this.plugins.owl = $('.owl-carousel');
          this.plugins.swiper = $('.swiper-container');
          this.plugins.progressLinear = $('.progress-linear');
          this.plugins.preloader = $('.preloader');
          this.plugins.selectFilter = $('select');
          this.plugins.rdNavbar = $('.rd-navbar');
          this.plugins.rdMailForm = $('.rd-mailform');
          this.plugins.rdInputLabel = $('.form-label');
          this.plugins.regula = $('[data-constraints]');
          this.plugins.search = $('.rd-search');
          this.plugins.searchResults = $('.rd-search-results');
          this.plugins.wow = $('.wow');
          this.plugins.maps = $('.google-map-container');
          this.plugins.countDown = $('.countdown');
          this.plugins.multitoggles = $('[data-multitoggle]');
          this.plugins.vide = $('.vide');
          this.plugins.rdRange = $('.rd-range');
          this.plugins.focusToggle = $('.focus-toggle');
          this.plugins.waypoint = $('[data-waypoint-to]');
          this.plugins.mParallax = $('.m-parallax');
          this.plugins.slick = $('.slick-slider');
        },
        handlePageTransition(){
            // Page loader & Page transition
            console.log(this.plugins.preloader);
            if (this.plugins.preloader.length && !this.isNoviBuilder && !this.preloaderLoaded) { 
                console.log('加载中123。。。');
                this.preloaderLoaded = true; // 设置为 true，避免重复加载
                pageTransition({
                  target: document.querySelector('.page'),
                  delay: 0,
                  duration: 200,
                  classIn: 'fadeIn',
                  classOut: 'fadeOut',
                  classActive: 'animated',
                  conditions: (event, link) => {
                    return link && !/(#|javascript:void\(0\)|callto:|tel:|mailto:|:\/\/)/.test(link);
                  },
                  onTransitionStart: (options) => {
                    console.log(123);
                    setTimeout(() => {
                      this.plugins.preloader.removeClass('loaded');
                    }, options.duration * 0.25);
                  },
                  onReady: () => {
                    console.log('去掉加载。。。');
                    this.plugins.preloader.addClass('loaded');
                    this.windowReady = true;
                  },
                });
            }
            // jQuery Count To
            if (this.plugins.counter.length) {
                for (let i = 0; i < this.plugins.counter.length; i++) {
                  const counter = $(this.plugins.counter[i]);
                  const initCount = () => {
                    const counter = $(this);
                    if (!counter.hasClass("animated-first") && this.isScrolledIntoView(counter)) {
                      counter.countTo({
                        refreshInterval: 40,
                        speed: counter.attr("data-speed") || 1000,
                        from: 0,
                        to: parseInt(counter.text(), 10),
                      });
                      counter.addClass('animated-first');
                    }
                  };
  
                  $.proxy(initCount, counter)();
                  this.$window.on("scroll", $.proxy(initCount, counter));
                }
            }

            // Progress bar
            if (this.plugins.progressLinear.length) {
                for (let i = 0; i < this.plugins.progressLinear.length; i++) {
                  const bar = $(this.plugins.progressLinear[i]);
                  const initProgress = () => {
                    const bar = $(this);
                    const end = parseInt($(this).find('.progress-value').text(), 10);
  
                    if (!bar.hasClass("animated-first") && this.isScrolledIntoView(bar)) {
                      bar.find('.progress-bar-linear').css({ width: end + '%' });
                      bar.find('.progress-value').countTo({
                        refreshInterval: 40,
                        from: 0,
                        to: end,
                        speed: 1000,
                        formatter: (value) => {
                          if (bar.data('formatter') === 'rating') {
                            return ('' + Math.round(value * 10) / 100).replace('.', ',');
                          }
                        },
                      });
                      bar.addClass('animated-first');
                    }
                  };
  
                  $.proxy(initProgress, bar)();
                  this.$window.on("scroll", $.proxy(initProgress, bar));
                }
            }
  
            // Material Parallax
            if (this.plugins.materialParallax.length) {
              if (!this.isNoviBuilder && !this.isIE && !this.isMobile) {
                this.plugins.materialParallax.parallax();
              } else {
                for (let i = 0; i < this.plugins.materialParallax.length; i++) {
                  const $parallax = $(this.plugins.materialParallax[i]);

                  $parallax.addClass('parallax-disabled');
                  $parallax.css({ "background-image": 'url(' + $parallax.data("parallax-img") + ')' });
                }
              }
            }
        },

        initializeSwiper() {
          // 使用 Swiper 的初始化代码
          const swiper = new Swiper('.swiper-container', {
            on: {
              init: () => {
                this.setBackgrounds(swiper);
                this.initCaptionAnimate(swiper);
              },
              slideChangeTransitionEnd: () => {
                this.initCaptionAnimate(swiper);
              },
            },
          });
        },
        setBackgrounds(swiper) {
          const swipersBg = swiper.el.querySelectorAll('[data-slide-bg]');
          swipersBg.forEach(swiperBg => {
            swiperBg.style.backgroundImage = 'url(' + swiperBg.getAttribute('data-slide-bg') + ')';
          });
        },
        initCaptionAnimate(swiper) {
          const captions = swiper.$wrapperEl[0].querySelectorAll('[data-caption-animate]');
          captions.forEach(caption => {
            caption.classList.remove('animated');
            caption.classList.remove(caption.getAttribute('data-caption-animate'));
            caption.classList.add('not-animated');
  
            if (caption.getAttribute('data-caption-delay')) {
              setTimeout(() => {
                this.animateCaption(caption);
              }, Number(caption.getAttribute('data-caption-delay')));
            } else {
              this.animateCaption(caption);
            }
          });
        },
        animateCaption(caption) {
          const duration = caption.getAttribute('data-caption-duration');
          if (duration) caption.style.animationDuration = duration + 'ms';
          caption.classList.remove('not-animated');
          caption.classList.add(caption.getAttribute('data-caption-animate'));
          caption.classList.add('animated');
        },
        initializeOwlCarousel() {
          const carousel = $('.owl-carousel');
          carousel.owlCarousel({
            autoplay: this.isNoviBuilder ? false : carousel.attr("data-autoplay") === "true",
            items: 1,
            // 其他配置...
          });
        },
        liveSearch(options) {
          $('#' + options.live).removeClass('cleared').html('');
          options.current++;
          options.spin.addClass('loading');
  
          $.get(options.handler, {
            s: decodeURI(options.term),
            liveSearch: options.live,
            dataType: "html",
            liveCount: options.liveCount,
            filter: options.filter,
            template: options.template,
          }, data => {
            options.processed++;
            const live = $('#' + options.live);
            if (options.processed === options.current && !live.hasClass('cleared')) {
              live.find('> #search-results').removeClass('active');
              live.html(data);
              setTimeout(() => {
                live.find('> #search-results').addClass('active');
              }, 50);
            }
            options.spin.parents('.rd-search').find('.input-group-addon').removeClass('loading');
          });
        },
        attachFormValidator(elements){
            this.plugins.regula.custom({
            name: 'PhoneNumber',
            defaultMessage: 'Invalid phone number format',
              validator: () => {
                  if (this.value === '') return true;
                  else return /^(\+\d)?[0-9-() ]{5,}$/i.test(this.value);
              }
            });
  
            for (var i = 0; i < elements.length; i++) {
              var o = $(elements[i]), v;
              o.addClass("form-control-has-validation").after("<span class='form-validation'></span>");
              v = o.parent().find(".form-validation");
              if (v.is(":last-child")) o.addClass("form-control-last-child");
            }
            elements.on('input change propertychange blur', (e) =>  {
              var $this = $(this), results;
  
              if (e.type !== "blur") if (!$this.parent().hasClass("has-error")) return;
              if ($this.parents('.rd-mailform').hasClass('success')) return;
  
              if (( results = $this.regula('validate') ).length) {
                for (i = 0; i < results.length; i++) {
                  $this.siblings(".form-validation").text(results[i].message).parent().addClass("has-error");
                }
              } else {
                $this.siblings(".form-validation").text("").parent().removeClass("has-error")
              }
            }).regula('bind');
  
            var regularConstraintsMessages = [
              {
                type: this.plugins.regula.Constraint.Required,
                newMessage: "The text field is required."
              },
              {
                type: this.plugins.regula.Constraint.Email,
                newMessage: "The email is not a valid email."
              },
              {
                type: this.plugins.regula.Constraint.Numeric,
                newMessage: "Only numbers are required"
              },
              {
                type: this.plugins.regula.Constraint.Selected,
                newMessage: "Please choose an option."
              }
            ];
            for (var isx = 0; isx < regularConstraintsMessages.length; isx++) {
              var regularConstraint = regularConstraintsMessages[isx];
  
              this.plugins.regula.override({
                constraintType: regularConstraint.type,
                defaultMessage: regularConstraint.newMessage
              });
            }
        },
        isValidated(elements, captcha){
          var results, errors = 0;
  
          if (elements.length) {
            for (var j = 0; j < elements.length; j++) {
  
              var $input = $(elements[j]);
              if ((results = $input.regula('validate')).length) {
                for (var k = 0; k < results.length; k++) {
                  errors++;
                  $input.siblings(".form-validation").text(results[k].message).parent().addClass("has-error");
                }
              } else {
                $input.siblings(".form-validation").text("").parent().removeClass("has-error")
              }
            }
  
            if (captcha) {
              if (captcha.length) {
                return this.validateReCaptcha(captcha) && errors === 0
              }
            }
  
            return errors === 0;
          }
          return true;
        },
        initOwlCarousel(c) {
            var aliaces = ["-", "-sm-", "-md-", "-lg-", "-xl-", "-xxl-"],
              values = [0, 576, 768, 992, 1200, 1600],
              responsive = {};

            for (var j = 0; j < values.length; j++) {
              responsive[values[j]] = {};
              for (var k = j; k >= -1; k--) {
                if (!responsive[values[j]]["items"] && c.attr("data" + aliaces[k] + "items")) {
                  responsive[values[j]]["items"] = k < 0 ? 1 : parseInt(c.attr("data" + aliaces[k] + "items"), 10);
                }
                if (!responsive[values[j]]["stagePadding"] && responsive[values[j]]["stagePadding"] !== 0 && c.attr("data" + aliaces[k] + "stage-padding")) {
                  responsive[values[j]]["stagePadding"] = k < 0 ? 0 : parseInt(c.attr("data" + aliaces[k] + "stage-padding"), 10);
                }
                if (!responsive[values[j]]["margin"] && responsive[values[j]]["margin"] !== 0 && c.attr("data" + aliaces[k] + "margin")) {
                  responsive[values[j]]["margin"] = k < 0 ? 30 : parseInt(c.attr("data" + aliaces[k] + "margin"), 10);
                }
              }
            }

            // Enable custom pagination
            if (c.attr('data-dots-custom')) {
              c.on("initialized.owl.carousel", function (event) {
                var carousel = $(event.currentTarget),
                  customPag = $(carousel.attr("data-dots-custom")),
                  active = 0;

                if (carousel.attr('data-active')) {
                  active = parseInt(carousel.attr('data-active'), 10);
                }

                carousel.trigger('to.owl.carousel', [active, 300, true]);
                customPag.find("[data-owl-item='" + active + "']").addClass("active");

                customPag.find("[data-owl-item]").on('click', function (e) {
                  e.preventDefault();
                  carousel.trigger('to.owl.carousel', [parseInt(this.getAttribute("data-owl-item"), 10), 300, true]);
                });

                carousel.on("translate.owl.carousel", function (event) {
                  customPag.find(".active").removeClass("active");
                  customPag.find("[data-owl-item='" + event.item.index + "']").addClass("active")
                });
              });
            }

            if (c.attr('data-nav-custom')) {
              c.on("initialized.owl.carousel", function (event) {
                var carousel = $(event.currentTarget),
                  customNav = $(carousel.attr("data-nav-custom"));

                // Custom Navigation Events
                customNav.find(".owl-arrow-next").click(function (e) {
                  e.preventDefault();
                  carousel.trigger('next.owl.carousel');
                });
                customNav.find(".owl-arrow-prev").click(function (e) {
                  e.preventDefault();
                  carousel.trigger('prev.owl.carousel');
                });
              });
            }

            c.on("initialized.owl.carousel", function () {
              this.initLightGalleryItem(c.find('[data-lightgallery="item"]'), 'lightGallery-in-carousel');
            });

            c.owlCarousel({
              autoplay: this.isNoviBuilder ? false : c.attr("data-autoplay") === "true",
              autoplayTimeout: c.attr('data-autoplay-speed') ? parseInt(c.attr('data-autoplay-speed')) : 3500,
              loop: this.isNoviBuilder ? false : c.attr("data-loop") !== "false",
              items: 1,
              autoWidth: c.attr("data-auto-width") === "true",
              center: c.attr("data-center") === "true",
              dotsContainer: c.attr("data-pagination-container") || false,
              navContainer: c.attr("data-navigation-container") || false,
              mouseDrag: this.isNoviBuilder ? false : c.attr("data-mouse-drag") !== "false",
              nav: c.attr("data-nav") === "true",
              dots: c.attr("data-dots") === "true",
              dotsEach: c.attr("data-dots-each") ? parseInt(c.attr("data-dots-each"), 10) : false,
              animateIn: c.attr('data-animation-in') ? c.attr('data-animation-in') : false,
              animateOut: c.attr('data-animation-out') ? c.attr('data-animation-out') : false,
              responsive: responsive,
              navText: c.attr("data-nav-text") ? $.parseJSON(c.attr("data-nav-text")) : [],
              navClass: c.attr("data-nav-class") ? $.parseJSON(c.attr("data-nav-class")) : ['owl-prev', 'owl-next']
            });

            return c;
        },
        validateReCaptcha(captcha){
          var captchaToken = captcha.find('.g-recaptcha-response').val();
  
          if (captchaToken.length === 0) {
            captcha
              .siblings('.form-validation')
              .html('Please, prove that you are not robot.')
              .addClass('active');
            captcha
              .closest('.form-wrap')
              .addClass('has-error');
  
            captcha.on('propertychange', () => {
              var $this = $(this),
                captchaToken = $this.find('.g-recaptcha-response').val();
  
              if (captchaToken.length > 0) {
                $this
                  .closest('.form-wrap')
                  .removeClass('has-error');
                $this
                  .siblings('.form-validation')
                  .removeClass('active')
                  .html('');
                $this.off('propertychange');
              }
            });
  
            return false;
          }
  
          return true;
        },
        initBootstrapTooltip(tooltipPlacement){
          this.plugins.bootstrapTooltip.tooltip('dispose');
          if (window.innerWidth < 576) {
            this.plugins.bootstrapTooltip.tooltip({placement: 'bottom'});
          } else {
            this.plugins.bootstrapTooltip.tooltip({placement: tooltipPlacement});
          } 
        },
        initLightGallery(itemsToInit, addClass){
          if ( !this.isNoviBuilder ) {
                  $( itemsToInit ).lightGallery( {
              thumbnail: $( itemsToInit ).attr( "data-lg-thumbnail" ) !== "false",
              selector: "[data-lightgallery='item']",
              autoplay: $( itemsToInit ).attr( "data-lg-autoplay" ) === "true",
              pause: parseInt( $( itemsToInit ).attr( "data-lg-autoplay-delay" ) ) || 5000,
              addClass: addClass,
              mode: $( itemsToInit ).attr( "data-lg-animation" ) || "lg-slide",
              loop: $( itemsToInit ).attr( "data-lg-loop" ) !== "false"
            } );
          }
        },
        initDynamicLightGallery ( itemsToInit, addClass ) {
          if ( !this.isNoviBuilder ) {
                  $( itemsToInit ).on( "click", () => {
              $( itemsToInit ).lightGallery( {
                thumbnail: $( itemsToInit ).attr( "data-lg-thumbnail" ) !== "false",
                selector: "[data-lightgallery='item']",
                autoplay: $( itemsToInit ).attr( "data-lg-autoplay" ) === "true",
                pause: parseInt( $( itemsToInit ).attr( "data-lg-autoplay-delay" ) ) || 5000,
                addClass: addClass,
                mode: $( itemsToInit ).attr( "data-lg-animation" ) || "lg-slide",
                loop: $( itemsToInit ).attr( "data-lg-loop" ) !== "false",
                dynamic: true,
                dynamicEl: JSON.parse( $( itemsToInit ).attr( "data-lg-dynamic-elements" ) ) || []
              } );
            } );
          }
        },
        initLightGalleryItem ( itemToInit, addClass ) {
          if ( !this.isNoviBuilder ) {
            $( itemToInit ).lightGallery( {
              selector: "this",
              addClass: addClass,
              counter: false,
              youtubePlayerParams: {
                modestbranding: 1,
                showinfo: 0,
                rel: 0,
                controls: 0
              },
              vimeoPlayerParams: {
                byline: 0,
                portrait: 0
              }
            } );
          }
        },
        getLatLngObject(str, marker, map, callback) {
          var coordinates = {};
          try {
            coordinates = JSON.parse(str);
            callback(new google.maps.LatLng(
              coordinates.lat,
              coordinates.lng
            ), marker, map)
          } catch (e) {
            map.geocoder.geocode({'address': str}, (results, status) => {
              if (status === google.maps.GeocoderStatus.OK) {
                var latitude = results[0].geometry.location.lat();
                var longitude = results[0].geometry.location.lng();
  
                callback(new google.maps.LatLng(
                  parseFloat(latitude),
                  parseFloat(longitude)
                ), marker, map)
              }
            })
          }
        },
        initMaps() {
          var key;
          for ( var i = 0; i < this.plugins.maps.length; i++ ) {
            if ( this.plugins.maps[i].hasAttribute( "data-key" ) ) {
              key = this.plugins.maps[i].getAttribute( "data-key" );
              break;
            }
          }
          $.getScript('//maps.google.com/maps/api/js?'+ ( key ? 'key='+ key + '&' : '' ) +'sensor=false&libraries=geometry,places&v=quarterly', () => {
            var head = document.getElementsByTagName('head')[0],
              insertBefore = head.insertBefore;
  
            head.insertBefore = (newElement, referenceElement) => {
              if (newElement.href && newElement.href.indexOf('//fonts.googleapis.com/css?family=Roboto') !== -1 || newElement.innerHTML.indexOf('gm-style') !== -1) {
                return;
              }
              insertBefore.call(head, newElement, referenceElement);
            };
            var geocoder = new google.maps.Geocoder;
            for (var i = 0; i < this.plugins.maps.length; i++) {
              var zoom = parseInt(this.plugins.maps[i].getAttribute("data-zoom"), 10) || 11;
              var styles = this.plugins.maps[i].hasAttribute('data-styles') ? JSON.parse(this.plugins.maps[i].getAttribute("data-styles")) : [];
              var center = this.plugins.maps[i].getAttribute("data-center") || "New York";
  
              // Initialize map
              var map = new google.maps.Map(this.plugins.maps[i].querySelectorAll(".google-map")[0], {
                zoom: zoom,
                styles: styles,
                scrollwheel: false,
                center: {lat: 0, lng: 0}
              });
  
              // Add map object to map node
              this.plugins.maps[i].map = map;
              this.plugins.maps[i].geocoder = geocoder;
              this.plugins.maps[i].keySupported = true;
              this.plugins.maps[i].google = google;
  
              // Get Center coordinates from attribute
              this.getLatLngObject(center, null, this.plugins.maps[i], (location, markerElement, mapElement) => {
                mapElement.map.setCenter(location);
              });
  
              // Add markers from google-map-markers array
              var markerItems = this.plugins.maps[i].querySelectorAll(".google-map-markers li");
  
              if (markerItems.length){
                var markers = [];
                for (var j = 0; j < markerItems.length; j++){
                  var markerElement = markerItems[j];
                  this.getLatLngObject(markerElement.getAttribute("data-location"), markerElement, this.plugins.maps[i], (location, markerElement, mapElement) => {
                    var icon = markerElement.getAttribute("data-icon") || mapElement.getAttribute("data-icon");
                    //var activeIcon = markerElement.getAttribute("data-icon-active") || mapElement.getAttribute("data-icon-active");
                    var info = markerElement.getAttribute("data-description") || "";
                    var infoWindow = new google.maps.InfoWindow({
                      content: info
                    });
                    markerElement.infoWindow = infoWindow;
                    var markerData = {
                      position: location,
                      map: mapElement.map
                    }
                    if (icon){
                      markerData.icon = icon;
                    }
                    var marker = new google.maps.Marker(markerData);
                    markerElement.gmarker = marker;
                    markers.push({markerElement: markerElement, infoWindow: infoWindow});
                    marker.isActive = false;
                    // Handle infoWindow close click
                    google.maps.event.addListener(infoWindow,'closeclick',((markerElement, mapElement) => {
                      var markerIcon = null;
                      markerElement.gmarker.isActive = false;
                      markerIcon = markerElement.getAttribute("data-icon") || mapElement.getAttribute("data-icon");
                      markerElement.gmarker.setIcon(markerIcon);
                    }).bind(this, markerElement, mapElement));
  
  
                    // Set marker active on Click and open infoWindow
                    google.maps.event.addListener(marker, 'click', ((markerElement, mapElement) => {
                      if (markerElement.infoWindow.getContent().length === 0) return;
                      var gMarker, currentMarker = markerElement.gmarker, currentInfoWindow;
                      for (var k =0; k < markers.length; k++){
                        var markerIcon;
                        if (markers[k].markerElement === markerElement){
                          currentInfoWindow = markers[k].infoWindow;
                        }
                        gMarker = markers[k].markerElement.gmarker;
                        if (gMarker.isActive && markers[k].markerElement !== markerElement){
                          gMarker.isActive = false;
                          markerIcon = markers[k].markerElement.getAttribute("data-icon") || mapElement.getAttribute("data-icon")
                          gMarker.setIcon(markerIcon);
                          markers[k].infoWindow.close();
                        }
                      }
  
                      currentMarker.isActive = !currentMarker.isActive;
                      if (currentMarker.isActive) {
                        markerIcon = markerElement.getAttribute("data-icon-active") || mapElement.getAttribute("data-icon-active");
                        if (markerIcon) {
                          currentMarker.setIcon(markerIcon);
                        }
                        currentInfoWindow.open(map, marker);
                      }else{
                        markerIcon = markerElement.getAttribute("data-icon") || mapElement.getAttribute("data-icon");
                        if (markerIcon) {
                          currentMarker.setIcon(markerIcon);
                        }
                        currentInfoWindow.close();
                      }
                    }).bind(this, markerElement, mapElement))
                  })
                }
              }
              }
            });
            if (this.plugins.captcha.length) {
              $.getScript("//www.google.com/recaptcha/api.js?onload=onloadCaptchaCallback&render=explicit&hl=en");
            }
        },
  
        // Multitoggles
        toggleElementsVisibility(targets) {
          for (var z = 0; z < targets.length; z++) {
            var hiddenElements = targets[z].querySelectorAll('[aria-hidden="true"]'),
              visibleElements = targets[z].querySelectorAll('[aria-hidden="false"]');
  
            for (let k = 0; k < hiddenElements.length; k++) {
              hiddenElements[k].setAttribute('aria-hidden', 'false');
            }
  
            for (let k = 0; k < visibleElements.length; k++) {
              visibleElements[k].setAttribute('aria-hidden', 'true');
            }
          }
        },
  
        transformView(element) {
          var elementTop = element.getBoundingClientRect().top,
            elementHeight = element.offsetHeight,
            parallaxImage = element.getElementsByClassName('m-parallax-image')[0],
            parallaxContent = element.getElementsByClassName('m-parallax-content')[0];
  
          if (window.scrollY > (elementTop + window.scrollY) // target not below
            || window.scrollY < (elementTop + window.scrollY + elementHeight)) { // target not above
            // Ratio between visible part and already scrolled [0; 1]
            var visiblePartRatio = (1 - (elementHeight - Math.abs(elementTop)) / elementHeight).toFixed(2);
  
            // Effect parameters
            var scaleDiff = 0.2, // difference between initial parallaxImage scale and resulting
              opacityStop = 1.2, // point [0; 1], where opacity value reaches maximum
              transformRatio = 0.2; // value, which define relative content scrolling within parallax element
  
            // Scale
            parallaxImage.style.transform = 'scale(' + (1 + scaleDiff * visiblePartRatio) + ')';
  
            // Opacity
            if (visiblePartRatio < opacityStop) {
              parallaxImage.style.opacity = 1 - visiblePartRatio * (1 / opacityStop);
            } else {
              parallaxImage.style.opacity = 0;
            }
  
            // Transforming Content
            parallaxContent.style.transform = 'translate3d(0, ' + -elementTop * transformRatio + 'px, 0)';
          }
        },
  
        bootstrap(){
          // Bootstrap Tooltips
          if (this.plugins.bootstrapTooltip.length) {
            var tooltipPlacement = this.plugins.bootstrapTooltip.attr('data-placement');
            this.initBootstrapTooltip(tooltipPlacement);
            this.$window.on('resize orientationchange', () =>  {
              this.initBootstrapTooltip(tooltipPlacement);
            })
          }
          // Bootstrap Modal
          if (this.plugins.bootstrapModal.length) {
            for (var i = 0; i < this.plugins.bootstrapModal.length; i++) {
              var modalItem = $(this.plugins.bootstrapModal[i]);
  
              modalItem.on('hidden.bs.modal', $.proxy( () => {
                var activeModal = $(this),
                  rdVideoInside = activeModal.find('video'),
                  youTubeVideoInside = activeModal.find('iframe');
  
                if (rdVideoInside.length) {
                  rdVideoInside[0].pause();
                }
  
                if (youTubeVideoInside.length) {
                  var videoUrl = youTubeVideoInside.attr('src');
  
                  youTubeVideoInside
                    .attr('src', '')
                    .attr('src', videoUrl);
                }
              }, modalItem))
            }
          }
  
          // Bootstrap tabs
          if (this.plugins.bootstrapTabs.length) {
              for (var ik = 0; ik < this.plugins.bootstrapTabs.length; ik++) {
            var bootstrapTabsItem = $(this.plugins.bootstrapTabs[ik]);
  
            //If have slick carousel inside tab - resize slick carousel on click
            if (bootstrapTabsItem.find('.slick-slider').length) {
              bootstrapTabsItem.find('.tabs-custom-list > li > a').on('click', $.proxy( () => {
                var $this = $(this);
                var setTimeOutTime = this.isNoviBuilder ? 1500 : 300;
  
                setTimeout( () => {
                  $this.find('.tab-content .tab-pane.active .slick-slider').slick('setPosition');
                }, setTimeOutTime);
              }, bootstrapTabsItem));
            }
  
            if(bootstrapTabsItem.attr('data-view-triggerable') === 'true') {
                  ((bootstrapTabsItem) => {
                    bootstrapTabsItem.on('shown.bs.tab', (event) => {
                      var prevTriggerable = bootstrapTabsItem.find('[data-view-trigger="' + event.relatedTarget.getAttribute('href') + '"]'),
                        triggerable = bootstrapTabsItem.find('[data-view-trigger="' + event.target.getAttribute('href') + '"]');
  
                      prevTriggerable.removeClass('active');
                      triggerable.addClass('active');
                    });
  
                  })(bootstrapTabsItem);
                }
              }
          }
  
          // Copyright Year (Evaluates correct copyright year)
          if (this.plugins.copyrightYear.length) {
            this.plugins.copyrightYear.text(this.initialDate.getFullYear());
          }
  
          // Google maps
          if( this.plugins.maps.length ) {
            this.lazyInit( this.plugins.maps, this.initMaps );
          }
  
          // Add custom styling options for input[type="checkbox"]
          if (this.plugins.checkbox.length) {
            for (var ix = 0; ix < this.plugins.checkbox.length; ix++) {
              $(this.plugins.checkbox[ix]).addClass("checkbox-custom").after("<span class='checkbox-custom-dummy'></span>")
            }
          }
  
          // UI To Top
          if (this.isDesktop && !this.isNoviBuilder) {
            $().UItoTop({
              easingType: 'easeOutQuad',
              containerClass: 'ui-to-top fa fa-angle-up'
            });
          }
  
          // RD Navbar
          if ( this.plugins.rdNavbar.length ) {
            var
              navbar = this.plugins.rdNavbar,
              aliases = { '-': 0, '-sm-': 576, '-md-': 768, '-lg-': 992, '-xl-': 1200, '-xxl-': 1600 },
              responsive = {};
  
            for ( var alias in aliases ) {
              var link = responsive[ aliases[ alias ] ] = {};
              if ( navbar.attr( 'data'+ alias +'layout' ) )          link.layout        = navbar.attr( 'data'+ alias +'layout' );
              if ( navbar.attr( 'data'+ alias +'device-layout' ) )   link.deviceLayout  = navbar.attr( 'data'+ alias +'device-layout' );
              if ( navbar.attr( 'data'+ alias +'hover-on' ) )        link.focusOnHover  = navbar.attr( 'data'+ alias +'hover-on' ) === 'true';
              if ( navbar.attr( 'data'+ alias +'auto-height' ) )     link.autoHeight    = navbar.attr( 'data'+ alias +'auto-height' ) === 'true';
              if ( navbar.attr( 'data'+ alias +'stick-up-offset' ) ) link.stickUpOffset = navbar.attr( 'data'+ alias +'stick-up-offset' );
              if ( navbar.attr( 'data'+ alias +'stick-up' ) )        link.stickUp       = navbar.attr( 'data'+ alias +'stick-up' ) === 'true';
              if ( this.isNoviBuilder ) link.stickUp = false;
              else if ( navbar.attr( 'data'+ alias +'stick-up' ) )   link.stickUp       = navbar.attr( 'data'+ alias +'stick-up' ) === 'true';
            }
  
            this.plugins.rdNavbar.RDNavbar({
              anchorNav: !this.isNoviBuilder,
              stickUpClone: (this.plugins.rdNavbar.attr("data-stick-up-clone") && !this.isNoviBuilder) ? this.plugins.rdNavbar.attr("data-stick-up-clone") === 'true' : false,
              responsive: responsive,
              callbacks: {
                onStuck:  () => {
                  var navbarSearch = $('.rd-search input');  
                  if (navbarSearch) {
                    navbarSearch.val('').trigger('propertychange');
                  }
                },
                onDropdownOver:  () => {
                  return !this.isNoviBuilder;
                },
                onUnstuck: function () {
                  if (this.$clone === null)
                    return;
  
                  var navbarSearch = this.$clone.find('.rd-search input');
  
                  if (navbarSearch) {
                    navbarSearch.val('').trigger('propertychange');
                    navbarSearch.trigger('blur');
                  }
  
                }
              }
            });
          }
  
          // RD Search
          if (this.plugins.search.length || this.plugins.searchResults) {
            var handler = "bat/rd-search.php";
            var defaultTemplate = '<h5 class="search-title"><a target="_top" href="#{href}" class="search-link">#{title}</a></h5>' +
              '<p>...#{token}...</p>' +
              '<p class="match"><em>Terms matched: #{count} - URL: #{href}</em></p>';
            var defaultFilter = '*.html';
  
            if (this.plugins.search.length) {
              for (var a = 0; a < this.plugins.search.length; a++) {
                var searchItem = $(this.plugins.search[a]),
                  options = {
                    element: searchItem,
                    filter: (searchItem.attr('data-search-filter')) ? searchItem.attr('data-search-filter') : defaultFilter,
                    template: (searchItem.attr('data-search-template')) ? searchItem.attr('data-search-template') : defaultTemplate,
                    live: (searchItem.attr('data-search-live')) ? searchItem.attr('data-search-live') : false,
                    liveCount: (searchItem.attr('data-search-live-count')) ? parseInt(searchItem.attr('data-search-live'), 10) : 4,
                    current: 0, processed: 0, timer: {}
                  };
  
                var $toggle = $('.rd-navbar-search-toggle');
                if ($toggle.length) {
                  $toggle.on('click', ( (searchItem) => {
                    return  () => {
                      if (!($(this).hasClass('active'))) {
                        searchItem.find('input').val('').trigger('propertychange');
                      }
                    }
                  })(searchItem));
                }
  
                if (options.live) {
                  var clearHandler = false;
  
                  searchItem.find('input').on("input propertychange", $.proxy( () => {
                    this.term = this.element.find('input').val().trim();
                    this.spin = this.element.find('.input-group-addon');
  
                    clearTimeout(this.timer);
  
                    if (this.term.length > 2) {
                      this.timer = setTimeout(this.liveSearch(this), 200);
  
                      if (clearHandler === false) {
                        clearHandler = true;
  
                        this.$body.on("click",  (e) => {
                          if ($(e.toElement).parents('.rd-search').length === 0) {
                            $('#rd-search-results-live').addClass('cleared').html('');
                          }
                        })
                      }
  
                    } else if (this.term.length === 0) {
                      $('#' + this.live).addClass('cleared').html('');
                    }
                  }, options, this));
                }
  
                searchItem.submit($.proxy( () => {
                  $('<input />').attr('type', 'hidden')
                    .attr('name', "filter")
                    .attr('value', this.filter)
                    .appendTo(this.element);
                  return true;
                }, options, this))
              }
            }
  
            if (this.plugins.searchResults.length) {
              var regExp = /\?.*s=([^&]+)&filter=([^&]+)/g;
              var match = regExp.exec(location.search);
  
              if (match !== null) {
                $.get(handler, {
                  s: decodeURI(match[1]),
                  dataType: "html",
                  filter: match[2],
                  template: defaultTemplate,
                  live: ''
                },  (data) => {
                  this.plugins.searchResults.html(data);
                })
              }
            }
          }
  
          // Swiper
          if (this.plugins.swiper.length) {
            for (var b = 0; b < this.plugins.swiper.length; b++) {
              let
                sliderMarkup = this.plugins.swiper[b],
                swiper,
                options = {
                  loop:             sliderMarkup.getAttribute('data-loop') === 'true' || false,
                  effect:           this.isIE ? 'slide' : sliderMarkup.getAttribute('data-slide-effect') || 'slide',
                  direction:        sliderMarkup.getAttribute('data-direction') || 'horizontal',
                  speed:            sliderMarkup.getAttribute('data-speed') ? Number(sliderMarkup.getAttribute('data-speed')) : 1000,
                  separateCaptions: sliderMarkup.getAttribute('data-separate-captions') === 'true' || false,
                  simulateTouch:    sliderMarkup.getAttribute('data-simulate-touch') && !this.isNoviBuilder ? sliderMarkup.getAttribute('data-simulate-touch') === "true" : false,
                  watchOverflow:    true
                };
              if (sliderMarkup.getAttribute('data-slide-effect') === 'coverflow' ) {
                options.coverflowEffect = {
                  slideShadows: false,
                  rotate:       0,
                  stretch:      290,
                  depth:        100,
                  modifier:     1
                }
              }
              if (sliderMarkup.getAttribute('data-autoplay')) {
                options.autoplay = {
                  delay:                Number(sliderMarkup.getAttribute('data-autoplay')) || 3000,
                  stopOnLastSlide:      false,
                  disableOnInteraction: true,
                  reverseDirection:     false,
                };
              }
              if (sliderMarkup.getAttribute('data-keyboard') === 'true') {
                options.keyboard = {
                  enabled:        sliderMarkup.getAttribute('data-keyboard') === 'true',
                  onlyInViewport: true
                };
              }
              if (sliderMarkup.getAttribute('data-mousewheel') === 'true') {
                options.mousewheel = {
                  releaseOnEdges: true,
                  sensitivity:    .1
                };
              }
              if (sliderMarkup.querySelector('.swiper-button-next, .swiper-button-prev')) {
                options.navigation = {
                  nextEl: '.swiper-button-next',
                  prevEl: '.swiper-button-prev'
                };
              }
              if (sliderMarkup.querySelector('.swiper-pagination')) {
                options.pagination = {
                  el:        '.swiper-pagination',
                  type:      'bullets',
                  clickable: true
                };
              }
              if (sliderMarkup.querySelector('.swiper-scrollbar')) {
                options.scrollbar = {
                  el:        '.swiper-scrollbar',
                  hide:      true,
                  draggable: true
                };
              }
              options.on = {
                init:  () => {
                  this.setBackgrounds(this);
                  this.setRealPrevious(this);
                  this.initCaptionAnimate(this);
                  // Real Previous Index must be set recent
                  this.on('slideChangeTransitionEnd',  () => {
                    this.setRealPrevious(this);
                  });
                }
              };
              /* eslint-disable no-unused-vars */
              swiper = new Swiper(this.plugins.swiper[i], options);
              /* eslint-enable no-unused-vars */
            }
          }
  
          // Owl carousel
          if ( this.plugins.owl.length ) {
            for (let i = 0; i < this.plugins.owl.length; i++) {
              let carousel = $(this.plugins.owl[i]);
              this.plugins.owl[i].owl = carousel;
              this.initOwlCarousel(carousel);
            }
          }
  
          // WOW
          if (this.$html.hasClass("wow-animation") && this.plugins.wow.length && !this.isNoviBuilder && this.isDesktop) {
            new WOW().init();
          }
  
          // RD Input Label
          if (this.plugins.rdInputLabel.length) {
            this.plugins.rdInputLabel.RDInputLabel();
          }
  
          // Regula
          if (this.plugins.regula.length) {
           // this.attachFormValidator(this.plugins.regula);
          }
          
          // MailChimp Ajax subscription
          if (this.plugins.mailchimp.length) {
            for (i = 0; i < this.plugins.mailchimp.length; i++) {
              var $mailchimpItem = $(this.plugins.mailchimp[i]),
                $email = $mailchimpItem.find('input[type="email"]');
  
              // Required by MailChimp
              $mailchimpItem.attr('novalidate', 'true');
              $email.attr('name', 'EMAIL');
  
              $mailchimpItem.on('submit', $.proxy(  ( $email, event ) => {
                event.preventDefault();
  
                var $this = this;
  
                var data = {},
                  url = $this.attr('action').replace('/post?', '/post-json?').concat('&c=?'),
                  dataArray = $this.serializeArray(),
                  $output = $("#" + $this.attr("data-form-output"));
  
                for (i = 0; i < dataArray.length; i++) {
                  data[dataArray[i].name] = dataArray[i].value;
                }
  
                $.ajax({
                  data: data,
                  url: url,
                  dataType: 'jsonp',
                  error:  (resp, text) => {
                    $output.html('Server error: ' + text);
  
                    setTimeout( () => {
                      $output.removeClass("active");
                    }, 4000);
                  },
                  success:  (resp) => {
                    $output.html(resp.msg).addClass('active');
                    $email[0].value = '';
                    var $label = $('[for="'+ $email.attr( 'id' ) +'"]');
                    if ( $label.length ) $label.removeClass( 'focus not-empty' );
  
                    setTimeout( () => {
                      $output.removeClass("active");
                    }, 6000);
                  },
                  /* eslint-disable no-unused-vars */
                  beforeSend:  (data) => {
                    var isNoviBuilder = window.xMode;
  
                    var isValidated = ( () => {
                      var results, errors = 0;
                      var elements = $this.find('[data-constraints]');
                      var captcha = null;
                      if (elements.length) {
                        for (var j = 0; j < elements.length; j++) {
  
                          var $input = $(elements[j]);
                          if ((results = $input.regula('validate')).length) {
                            for (var k = 0; k < results.length; k++) {
                              errors++;
                              $input.siblings(".form-validation").text(results[k].message).parent().addClass("has-error");
                            }
                          } else {
                            $input.siblings(".form-validation").text("").parent().removeClass("has-error")
                          }
                        }
  
                        if (captcha) {
                          if (captcha.length) {
                            return this.validateReCaptcha(captcha) && errors === 0
                          }
                        }
  
                        return errors === 0;
                      }
                      return true;
                    })();
  
                    // Stop request if builder or inputs are invalide
                    if (isNoviBuilder || !isValidated)
                      return false;
  
                    $output.html('Submitting...').addClass('active');
                  }
                });
  
                return false;
              }, $mailchimpItem, $email ));
            }
          }
  
          // Campaign Monitor ajax subscription
          if (this.plugins.campaignMonitor.length) {
            for (i = 0; i < this.plugins.campaignMonitor.length; i++) {
              var $campaignItem = $(this.plugins.campaignMonitor[i]);
  
              $campaignItem.on('submit', $.proxy( (e) => {
                var data = {},
                  url = this.attr('action'),
                  dataArray = this.serializeArray(),
                  $output = $("#" + this.plugins.campaignMonitor.attr("data-form-output")),
                  $this = $(this);
  
                for (i = 0; i < dataArray.length; i++) {
                  data[dataArray[i].name] = dataArray[i].value;
                }
  
                $.ajax({
                  data: data,
                  url: url,
                  dataType: 'jsonp',
                  error:  (resp, text) => {
                    $output.html('Server error: ' + text);
  
                    setTimeout( () => {
                      $output.removeClass("active");
                    }, 4000);
                  },
                  success:  (resp) => {
                    $output.html(resp.Message).addClass('active');
  
                    setTimeout( () => {
                      $output.removeClass("active");
                    }, 6000);
                  },
                  beforeSend:  (data) => {
                    // Stop request if builder or inputs are invalide
                    if (this.isNoviBuilder || !this.isValidated($this.find('[data-constraints]')))
                      return false;
  
                    $output.html('Submitting...').addClass('active');
                  }
                });
  
                // Clear inputs after submit
                var inputs = $this[0].getElementsByTagName('input');
                for (var i = 0; i < inputs.length; i++) {
                  inputs[i].value = '';
                  var label = document.querySelector( '[for="'+ inputs[i].getAttribute( 'id' ) +'"]' );
                  if( label ) label.classList.remove( 'focus', 'not-empty' );
                }
  
                return false;
              }, $campaignItem));
            }
          }
  
          // // RD Mailform
          // if (this.plugins.rdMailForm.length) {
          //   /* eslint-disable no-unused-vars */
          //   var j, k,
          //     msg = {
          //       'MF000': 'Successfully sent!',
          //       'MF001': 'Recipients are not set!',
          //       'MF002': 'Form will not work locally!',
          //       'MF003': 'Please, define email field in your form!',
          //       'MF004': 'Please, define type of your form!',
          //       'MF254': 'Something went wrong with PHPMailer!',
          //       'MF255': 'Aw, snap! Something went wrong.'
          //     };
  
          //   for (i = 0; i < this.plugins.rdMailForm.length; i++) {
          //     var $form = $(this.plugins.rdMailForm[i]),
          //       formHasCaptcha = false;
  
          //     $form.attr('novalidate', 'novalidate').ajaxForm({
          //       data: {
          //         "form-type": $form.attr("data-form-type") || "contact",
          //         "counter": i
          //       },
          //       beforeSubmit:  (arr, $form, options) => {
          //         if (this.isNoviBuilder)
          //           return;
  
          //         var form = $(this.plugins.rdMailForm[this.extraData.counter]),
          //           inputs = form.find("[data-constraints]"),
          //           output = $("#" + form.attr("data-form-output")),
          //           captcha = form.find('.recaptcha'),
          //           captchaFlag = true;
  
          //         output.removeClass("active error success");
  
          //         if (this.isValidated(inputs, captcha)) {
  
          //           // veify reCaptcha
          //           if (captcha.length) {
          //             var captchaToken = captcha.find('.g-recaptcha-response').val(),
          //               captchaMsg = {
          //                 'CPT001': 'Please, setup you "site key" and "secret key" of reCaptcha',
          //                 'CPT002': 'Something wrong with google reCaptcha'
          //               };
  
          //             formHasCaptcha = true;
  
          //             $.ajax({
          //               method: "POST",
          //               url: "bat/reCaptcha.php",
          //               data: {'g-recaptcha-response': captchaToken},
          //               async: false
          //             })
          //               .done( (responceCode) => {
          //                 if (responceCode !== 'CPT000') {
          //                   if (output.hasClass("snackbars")) {
          //                     output.html('<p><span class="icon text-middle mdi mdi-check icon-xxs"></span><span>' + captchaMsg[responceCode] + '</span></p>')
  
          //                     setTimeout( () => {
          //                       output.removeClass("active");
          //                     }, 3500);
  
          //                     captchaFlag = false;
          //                   } else {
          //                     output.html(captchaMsg[responceCode]);
          //                   }
  
          //                   output.addClass("active");
          //                 }
          //               });
          //           }
  
          //           if (!captchaFlag) {
          //             return false;
          //           }
  
          //           form.addClass('form-in-process');
  
          //           if (output.hasClass("snackbars")) {
          //             output.html('<p><span class="icon text-middle fa fa-circle-o-notch fa-spin icon-xxs"></span><span>Sending</span></p>');
          //             output.addClass("active");
          //           }
          //         } else {
          //           return false;
          //         }
          //       },
          //       error:  (result) => {
          //         if (this.isNoviBuilder)
          //           return;
  
          //         var output = $("#" + $(this.plugins.rdMailForm[this.extraData.counter]).attr("data-form-output")),
          //           form = $(this.plugins.rdMailForm[this.extraData.counter]);
  
          //         output.text(msg[result]);
          //         form.removeClass('form-in-process');
  
          //         if (formHasCaptcha) {
          //           // grecaptcha.reset();
          //         }
          //       },
          //       success:  (result) => {
          //         if (this.isNoviBuilder)
          //           return;
  
          //         var form = $(this.plugins.rdMailForm[this.extraData.counter]),
          //           output = $("#" + form.attr("data-form-output")),
          //           select = form.find('select');
  
          //         form
          //           .addClass('success')
          //           .removeClass('form-in-process');
  
          //         if (formHasCaptcha) {
          //           // grecaptcha.reset();
          //         }
  
          //         result = result.length === 5 ? result : 'MF255';
          //         output.text(msg[result]);
  
          //         if (result === "MF000") {
          //           if (output.hasClass("snackbars")) {
          //             output.html('<p><span class="icon text-middle mdi mdi-check icon-xxs"></span><span>' + msg[result] + '</span></p>');
          //           } else {
          //             output.addClass("active success");
          //           }
          //         } else {
          //           if (output.hasClass("snackbars")) {
          //             output.html(' <p class="snackbars-left"><span class="icon icon-xxs mdi mdi-alert-outline text-middle"></span><span>' + msg[result] + '</span></p>');
          //           } else {
          //             output.addClass("active error");
          //           }
          //         }
  
          //         form.clearForm();
  
          //         if (select.length) {
          //           select.select2("val", "");
          //         }
  
          //         form.find('input, textarea').trigger('blur');
  
          //         setTimeout( () => {
          //           output.removeClass("active error success");
          //           form.removeClass('success');
          //         }, 3500);
          //       }
          //     });
          //   }
          // }
  
          // lightGallery
          if (this.plugins.lightGallery.length) {
            for (var f = 0; f < this.plugins.lightGallery.length; f++) {
              this.initLightGallery(this.plugins.lightGallery[f]);
            }
          }
  
          // lightGallery item
          if (this.plugins.lightGalleryItem.length) {
            // Filter carousel items
            var notCarouselItems = [];
  
            for (var z = 0; z < this.plugins.lightGalleryItem.length; z++) {
              if (!$(this.plugins.lightGalleryItem[z]).parents('.owl-carousel').length &&
                !$(this.plugins.lightGalleryItem[z]).parents('.swiper-slider').length &&
                !$(this.plugins.lightGalleryItem[z]).parents('.slick-slider').length) {
                notCarouselItems.push(this.plugins.lightGalleryItem[z]);
              }
            }
  
            this.plugins.lightGalleryItem = notCarouselItems;
  
            for (let i = 0; i < this.plugins.lightGalleryItem.length; i++) {
              this.initLightGalleryItem(this.plugins.lightGalleryItem[i]);
            }
          }
  
          // Dynamic lightGallery
          if (this.plugins.lightDynamicGalleryItem.length) {
            for (let i = 0; i < this.plugins.lightDynamicGalleryItem.length; i++) {
              this.initDynamicLightGallery(this.plugins.lightDynamicGalleryItem[i]);
            }
          }
  
          // jQuery Countdown *
          if (this.plugins.countDown.length) {
            for (let i = 0; i < this.plugins.countDown.length; i++) {
              var $countDownItem = $(this.plugins.countDown[i]),
                settings = {
                  format: $countDownItem.attr('data-format'),
                  layout: $countDownItem.attr('data-layout')
                };
  
              // Classic style
              if ($countDownItem.attr('data-style') === 'short') {
                settings['labels'] = ['Yeas', 'Mons', 'Weks', 'Days', 'Hrs', 'Min', 'Sec'];
              }
  
              if (this.livedemo) {
                var d = new Date();
                d.setDate(d.getDate() + 42);
                settings[$countDownItem.attr('data-type')] = d;
              } else {
                settings[$countDownItem.attr('data-type')] = new Date($countDownItem.attr('data-time'));
              }
  
              $countDownItem.countdown(settings);
            }
          }
  
          if (this.plugins.multitoggles.length) {
            for (let i = 0; i < this.plugins.multitoggles.length; i++) {
              var
                node = this.plugins.multitoggles[i],
                toggle = new Toggle({
                  node: node,
                  targets: document.querySelectorAll(node.getAttribute('data-multitoggle')),
                  scope: document.querySelectorAll(node.getAttribute('data-scope')),
                  isolate: document.querySelectorAll(node.getAttribute('data-isolate'))
                });
  
              if (this.plugins.multitoggles[i].classList.contains('content-toggle')) {
                node.addEventListener('click',  () => {
                  this.toggleElementsVisibility(this.mt.targets);
                });
              }
            }
          }
  
           // Vide
          for ( let i = 0; i < this.plugins.vide.length; i++ ) {
            let
              $element = $(this.plugins.vide[i]),
              options = $element.data('vide-options'),
              path = $element.data('vide-bg');
  
            if ( !this.isMobile ) {
              $element.vide( path, options );
  
              let
                videObj = $element.data('vide').getVideoObject(),
                videoTrigger = $($element.find('[data-vide-toggle]')),
                scrollHandler = (( $element ) => {
                  if ( this.isScrolledIntoView( $element ) ) this.play();
                  else this.pause();
                }).bind( videObj, $element );
  
              scrollHandler();
              if ( this.isNoviBuilder ) videObj.pause();
              else document.addEventListener( 'scroll', scrollHandler );
  
                videObj.addEventListener('play', () => {
                    $element.removeClass('video-paused');
                    $element.addClass('video-playing');
                });

                videObj.addEventListener('pause', () => {
                    $element.removeClass('video-playing');
                    $element.addClass('video-paused');
                });

  
                videoTrigger.on('click', (e) => {
                    e.preventDefault();
                    if (videObj.paused) {
                        videObj.play();
                    } else {
                        videObj.pause();
                    }
                });

            } else {
              $element.css({ 'background-image': 'url('+ path +'.jpg)' });
            }
          }
  
          // RD Range
          if (this.plugins.rdRange.length && !this.isNoviBuilder) {
            this.plugins.rdRange.RDRange({});
          }
  
          // Focus toggle
          if (this.plugins.focusToggle.length) {
            for (let i = 0; i < this.plugins.focusToggle.length; i++) {
              var $element = $(this.plugins.focusToggle[i]);
  
              $element.hover(
                 (event) => {
                  event.preventDefault();
                  $(this).parents('.focus-toggle-parent').addClass('focus');
                }
              );
  
              $element.parents('.focus-toggle-parent').hover(
                 () => {
                },
                 () => {
                  $(this).removeClass('focus');
                }
              )
            }
          }
  
          // Waypoint
          if (this.plugins.waypoint.length && !this.isNoviBuilder) {
            for (let i = 0; i < this.plugins.waypoint.length; i++) {
              var $waypoint = $(this.plugins.waypoint[i]);
              $waypoint.on('click', ( ($waypoint) => {
                return  (e) => {
                  e.preventDefault();
                  e.stopPropagation();
                  var offset = $($waypoint.attr('data-waypoint-to')).offset().top + 2;
                  if ($waypoint.attr('data-waypoint-relative-to')) {
                    var relatives = document.querySelectorAll($waypoint.attr('data-waypoint-relative-to'));
                    for (let j = 0; j < relatives.length; j++) {
                      offset -= relatives[j].offsetHeight;
                    }
                  }
                  if ($waypoint.attr('data-waypoint-offset')) {
                    offset -= $waypoint.attr('data-waypoint-offset');
                  }
  
                  $('html, body').stop().animate({
                    scrollTop: offset
                  }, 800);
                }
              })($waypoint));
            }
          }
          
          // Select 2
          if ( this.plugins.selectFilter.length ) {
            for ( let i = 0; i < this.plugins.selectFilter.length; i++ ) {
              var select = $( this.plugins.selectFilter[ i ] );
              select.select2( {
                dropdownParent:          $( '.page' ),
                placeholder:             select.attr( 'data-placeholder' ) || null,
                minimumResultsForSearch: select.attr( 'data-minimum-results-search' ) || Infinity,
                containerCssClass:       select.attr( 'data-container-class' ) || null,
                dropdownCssClass:        select.attr( 'data-dropdown-class' ) || null
              } );
            }
          }
  
          // M Parallax
          if (this.plugins.mParallax.length && !this.isNoviBuilder && !this.isIE && !this.isMobile) {
            for (let i = 0; i < this.plugins.mParallax.length; i++) {
              var parallaxElement = this.plugins.mParallax[i];
              window.addEventListener('scroll', ( () => {
                return  () => {
                  this.transformView(parallaxElement);
                }
              })(parallaxElement));
              this.transformView(parallaxElement);
            }
          }
  
          // Slick carousel
          if (this.plugins.slick.length) {
            for (let i = 0; i < this.plugins.slick.length; i++) {
              var $slickItem = $(this.plugins.slick[i]);
  
              $slickItem.on('init', (slick) => {
                this.initLightGallery($('[data-lightgallery="group-slick"]'), 'lightGallery-in-carousel');
                this.initLightGallery($('[data-lightgallery="item-slick"]'), 'lightGallery-in-carousel');
                });
  
              $slickItem.slick({
                slidesToScroll: parseInt($slickItem.attr('data-slide-to-scroll'), 10) || 1,
                asNavFor: $slickItem.attr('data-for') || false,
                dots: $slickItem.attr("data-dots") === "true",
                infinite: this.isNoviBuilder ? false : $slickItem.attr("data-loop") === "true",
                focusOnSelect: true,
                arrows: $slickItem.attr("data-arrows") === "true",
                swipe: $slickItem.attr("data-swipe") === "true",
                autoplay: $slickItem.attr("data-autoplay") === "true",
                vertical: $slickItem.attr("data-vertical") === "true",
                centerMode: $slickItem.attr("data-center-mode") === "true",
                variableWidth: $slickItem.attr("data-auto-width") === "true",
                centerPadding: $slickItem.attr("data-center-padding") ? $slickItem.attr("data-center-padding") : '0.50',
                verticalSwiping: $slickItem.attr('data-vertical-swiping') === 'true' || false,
                mobileFirst: true,
                responsive: [
                  {
                    breakpoint: 0,
                    settings: {
                      slidesToShow: parseInt($slickItem.attr('data-items'), 10) || 1,
                      vertical: $slickItem.attr('data-vertical') === 'true' || false
                    }
                  },
                  {
                    breakpoint: 575,
                    settings: {
                      slidesToShow: parseInt($slickItem.attr('data-sm-items'), 10) || 1,
                      vertical: $slickItem.attr('data-sm-vertical') === 'true' || false
                    }
                  },
                  {
                    breakpoint: 767,
                    settings: {
                      slidesToShow: parseInt($slickItem.attr('data-md-items'), 10) || 1,
                      vertical: $slickItem.attr('data-md-vertical') === 'true' || false
                    }
                  },
                  {
                    breakpoint: 991,
                    settings: {
                      slidesToShow: parseInt($slickItem.attr('data-lg-items'), 10) || 1,
                      vertical: $slickItem.attr('data-lg-vertical') === 'true' || false
                    }
                  },
                  {
                    breakpoint: 1199,
                    settings: {
                      slidesToShow: parseInt($slickItem.attr('data-xl-items'), 10) || 1,
                      vertical: $slickItem.attr('data-xl-vertical') === 'true' || false
                    }
                  }
                ]
              })
                .on('afterChange',  (event, slick, currentSlide, nextSlide) => {
                  var $this = $(this),
                    childCarousel = $this.attr('data-child');
  
                  if (childCarousel) {
                    $(childCarousel + ' .slick-slide').removeClass('slick-current');
                    $(childCarousel + ' .slick-slide').eq(currentSlide).addClass('slick-current');
                  }
                });
  
            }
          }
  
        }
      },
    mounted() {
      this.initPlugins();     
      this.isNoviBuilder = window.xMode;
      this.isDesktop = document.documentElement.classList.contains("desktop");
      this.isIE = this.detectIE();
      this.isMobile = /Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini/i.test(navigator.userAgent);
      // this.onload();
      this.initializeSwiper();
      this.initializeOwlCarousel();
      // Additional class on html if mac os.
      if (navigator.platform.match(/(Mac)/i)) {
        this.$html.addClass("mac-os");
      }
      if (this.isIE) {
        if (this.isIE === 12) this.$html.addClass("ie-edge");
        if (this.isIE === 11) this.$html.addClass("ie-11");
        if (this.isIE < 10) this.$html.addClass("lt-ie-10");
        if (this.isIE < 11) this.$html.addClass("ie-10");
      }
      this.bootstrap();
      console.log('AppHeader mounted');
    },
    beforeUnmount() {
        console.log('AppHeader beforeUnmount');
    },
    created(){
      
    },
    watch: {

    }

  }
  </script>
  
  <style scoped>

  </style>
  