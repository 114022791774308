<template>
    <div>
      <AppHeader ref="appHeader">
        <template v-slot:bg>
            <div class="page-header-1-figure m-parallax-figure">
            <div class="page-header-1-image m-parallax-image" style="background-image: url(images/bg-image-7.jpg);"></div>
            </div>
        </template>
        <template v-slot:customFormItem>
            <section class="breadcrumbs-custom">
            <div class="breadcrumbs-custom-inner">
                <div class="container">
                <div class="breadcrumbs-custom-main m-parallax-content">
                    <h2 class="breadcrumbs-custom-title">{{$t('soho_choice')}}</h2>
                    <p>{{$t('buy_title')}}</p>
                </div>
                </div>
            </div>
            </section>
        </template>
        <template v-slot:sectionItem>
            <section class="section section-md bg-gray-100">
                <div class="container">
                <div class="row row-40 row-xl-50 flex-lg-row-reverse">
                    <div class="col-lg-4">
                    <div class="block-custom-centered">
                        <div class="box-4-outer">
                        <button class="box-4-toggle" data-multitoggle="#box-4" data-scope=".box-4-outer" aria-label="Filter Toggle">
                            <span>Search</span><span class="icon mdi mdi-magnify">
                            </span></button>
                        <article class="box-4" id="box-4">
                            <div class="box-4-inner">
                            <h3>{{ $t('find_your_car') }}</h3>
                            <form @submit.prevent="submitSearch(1)">

                                <div class="form-wrap mt-40 mt-xl-55" style="padding-top: 20px;">
                                <button class="button button-lg button-primary-color-dark button-block" v-loading="submitLoding" type="submit">{{$t('search')}}</button>
                                </div>

                                <div class="form-wrap form-wrap-icon">
                                    <label class="form-label form-label-outside" for="form-title">{{$t('search_by_car_name')}}</label>
                                    <input v-model="searchItem.name" class="form-input" id="form-title" type="text" name="title" />
                                    <div class="icon form-icon mdi mdi-magnify"></div>
                                </div>
                                <div class="form-wrap form-wrap-icon">
                                    <el-select v-model="searchItem.vehicle_model" :placeholder="$t('vehicle_type')" class="form-input" id="vehicle_model" name="vehicle_model">
                                        <el-option :label="$t('sedan')" value="SEDAN"></el-option>
                                        <el-option :label="$t('mpv')" value="MPV"></el-option>
                                        <el-option :label="$t('suv')" value="SUV"></el-option>
                                        <el-option :label="$t('hatchback')" value="Hatchback"></el-option>
                                    </el-select>
                                <div class="icon form-icon mdi mdi-car"></div>
                                </div>

                                <div class="form-wrap form-wrap-icon form-wrap-select"> 
                                    <div class="form-wrap form-wrap-icon form-wrap-select">
                                        <el-select v-model="searchItem.type" :placeholder="$t('transmission')" class="form-input no-border-select" id="type" name="type">
                                            <el-option :label="$t('automatic')" value="Automatic"></el-option>
                                            <el-option :label="$t('manual')" value="Manual"></el-option>
                                        </el-select>
                                        <div class="icon form-icon mdi mdi-settings"></div>
                                    </div>
                                </div>

                                <div class="form-wrap">
                                <div class="rd-range-group-modern">
                                    <div class="block">
                                        <el-slider
                                        v-model="priceRange"
                                        range
                                        show-stops
                                        :max="100000"
                                        :step="1000"
                                        :min="0"
                                        @input="updatePriceRange">
                                        </el-slider>
                                    </div>
  
                                    <div class="rd-range-info">
                                    <p class="rd-range-info-text">{{ $t('price_range') }}</p>
                                    <div class="rd-range-values">
                                        <input v-model="searchItem.priceMin" class="rd-range-input-value-1" type="text" name="value-1" />
                                        <span class="rd-range-values-separator">-</span>
                                        <input v-model="searchItem.priceMax" class="rd-range-input-value-2" type="text" name="value-2" />
                                    </div>
                                    </div>
                                </div>
                                </div>

                                <div class="form-wrap mt-md-30">
                                <ul class="list-sm">
                                    <!-- <li v-for="(name, index) in carBrands" :key="index"> -->
                                    
                                        <el-checkbox-group v-model="searchItem.brands">
                                            <el-checkbox v-for="name in carBrands" :label="name" :key="name" class="vertical-checkbox">
                                                <label class="checkbox-inline">{{name}}</label>
                                            </el-checkbox>
                                        </el-checkbox-group>
                                    
                                    <!-- </li> -->
                                </ul>
                                </div>
                            </form>
                            </div>
                        </article>
                        </div>
                    </div>
                    </div>
                    <div class="col-lg-8">

                        <div class="row row-30 row-xl-40" v-loading="loading">
                            <!-- 判断 cars 是否为空 -->
                            <div v-if="cars.length === 0" class="no-results">
                                No results found.
                            </div>
                            <div class="col-sm-6" v-for="car in cars" :key="car.id">
                                <article class="tour-classic">
                                <div class="tour-classic-media">
                                    <router-link :to="`/car?id=${car.id}`" class="tour-classic-figure">
                                    <img class="tour-classic-image" :src="car.image" alt="" width="369" height="248"/>
                                    </router-link>
                                    <div class="tour-classic-pricing">
                                    <p class="tour-classic-price tour-classic-price-new">{{car.label}}</p>
                                    </div>
                                </div>
                                <div class="tour-classic-body">
                                    <h4 class="tour-classic-title">
                                    <router-link :to="`/car?id=${car.id}`">{{ car.name }}</router-link>
                                    </h4>
                                    <p class="tour-classic-caption">{{ car.price }}</p>
                                    <div class="tour-classic-footer">
                                    <div class="tour-classic-footer-right">
                                        <div class="object-inline">
                                        <span class="icon icon-lg mdi mdi-speedometer"></span>
                                        <span class="text-gray-2-60">{{ car.mileage }}</span>
                                        </div>
                                        <div class="object-inline">
                                        <span class="icon icon-lg mdi mdi-settings"></span>
                                        <span class="text-gray-2-60">{{ car.transmission }}</span>
                                        </div>
                                        <div class="object-inline">
                                        <span class="icon icon-lg mdi mdi-map-marker"></span>
                                        <span class="text-gray-2-60">{{ car.location }}</span>
                                        </div>
                                    </div>
                                    </div>
                                </div>
                                </article>
                            </div>
                            </div>

                            <nav class="pagination-outer text-center">
                            <ul class="pagination">
                                <li class="page-item" v-for="page in pagination.last_page" :key="page" :class="{ active: page === pagination.current_page }">
                                <a class="page-link" href="#" @click.prevent="changePage(page)">{{ page }}</a>
                                </li>
                            </ul>
                            </nav>
                    </div>
                </div>
                </div>
            </section>
        </template>
        </AppHeader>
      <!-- 你的其他页面内容 -->
      <AppFooter />
    </div>
  </template>
  
  <script>
  import AppHeader from '../components/AppHeader.vue';
  import AppFooter from '../components/AppFooter.vue';
  import axios from 'axios';
  
  export default {
    name: "Buy",
    components: {
      AppHeader,
      AppFooter
    },
    data() {
        return {
            submitLoding: false,
            searchItem: {
                name: '',
                priceMin: 0,
                priceMax: 100000,
                type: '',
                vehicle_model: '',
                brands: [],
                // modelList: [
                //     'SEDAN',
                //     'HATCHBACK',
                //     'SUV',
                //     'MPV',
                //     'COUPE',
                //     'ROADSTER',
                //     'CABRIO',
                //     'CROSSOVER',
                //     'PICKUP',
                //     'CAR UTILITY',
                //     'WAGON'
                // ],
            },
            loading: true,
            cars: [],
            pagination: {
                current_page: 1,
                last_page: 1,
                total: 0,
            },
            carBrands: []
        };
    },
    methods: {
        // 当滑块的值变化时，更新价格输入框的值
        updatePriceRange() {
            this.searchItem.priceMin = this.priceRange[0];
            this.searchItem.priceMax = this.priceRange[1];
        },
        async submitSearch(page = 1) {
            this.loading = true;
            this.submitLoding = true;
            try {
                axios.post(`/searchCars?page=${page}`, this.searchItem).then((response) => {
                
                    this.cars = response.data.cars;
                    this.pagination = response.data.pagination;
                    this.loading = false;
                    console.log('Search Results:', response.data);
                    this.submitLoding = false;
                });
                
            } catch (error) {
                console.error('Search failed:', error);
                this.submitLoding = false;
                this.loading = false;
            }
        },
        // 获取指定页码的汽车数据
        async fetchCars(page = 1) {
            axios.post(`/searchCars?page=${page}`, this.searchItem).then((response) => {
                this.cars = response.data.cars;
                this.pagination = response.data.pagination;
                this.loading = false;
            });
        },
        async getBrands(page = 1){
            axios.post(`/getCarList?page=${page}`, this.searchItem).then((response) => {
                this.carBrands =  response.data.brand;
            });
        },
        // 处理分页的切换
        changePage(page) {
            this.loading = true;
            this.pagination.current_page = page;
            this.fetchCars(page);  // 根据页码获取新数据
        },
    },
    mounted() {
        this.loading = true;
        let brandsQuery = this.$route.query.brand;
        let vehicleType = this.$route.query.vehicleType;
        let priceRange = this.$route.query.priceRange;
        let transmission = this.$route.query.transmission;
        if(brandsQuery){
            this.searchItem.brands = [brandsQuery];
        }
        if(vehicleType){
            this.searchItem.vehicle_model = vehicleType;
        }
        if(transmission){
            this.searchItem.type = transmission;
        }
        if(priceRange){
            //this.searchItem.price = priceRange;

            switch(priceRange){
                case '1':
                this.searchItem.priceMin = 0;
                this.searchItem.priceMax = 15000;
                break;
                case '2':
                this.searchItem.priceMin = 15000;
                this.searchItem.priceMax = 20000;
                break;
                case '3':
                this.searchItem.priceMin = 20000;
                this.searchItem.priceMax = 30000;
                break;
                case '4':
                this.searchItem.priceMin = 30000;
                this.searchItem.priceMax = 50000;
                break;
                case '5':
                this.searchItem.priceMin = 50000;
                this.searchItem.priceMax = 100000;
                break;
            }
            
        }
        this.fetchCars();
        this.getBrands();
        this.$refs.appHeader.handlePageTransition();
    },
    computed: {
        priceRange: {
            get() {
            return [this.searchItem.priceMin, this.searchItem.priceMax];
            },
            set(value) {
            this.searchItem.priceMin = value[0];
            this.searchItem.priceMax = value[1];
            }
        }
    }
  };
  </script>
<style scoped>
/* 提高优先级 */
.el-slider {
    --el-slider-main-bg-color: #FCC12A !important; /* 强制覆盖 */
}
::v-deep .el-checkbox__input.is-checked .el-checkbox__inner {
    background-color: #fcc12a !important;
    border-color: #fcc12a !important;
}
::v-deep .el-checkbox__input.is-checked + .el-checkbox__label {
    color: #fcc12a !important;
}
::v-deep .el-loading-mask {
    z-index: 10 !important;
}
::v-deep .el-loading-spinner .path{
    stroke: #fcc12a;
}
::v-deep .el-select__wrapper {
    box-shadow: 0 0 0 0px rgba(0, 0, 0, 0.25) inset !important;    /* 移除可能的阴影 */
}
::v-deep .el-select-dropdown__item.is-selected{
    color: #fcc12a;
}
.vertical-checkbox {
    display: block;
    margin-bottom: 10px; /* 添加一些下边距使得间距更好 */
}

</style>
