// src/i18n.js
import { createI18n } from 'vue-i18n';

const messages = {
    ru: {
        home: 'Домашняя страница',
        buy: 'Покупка',
        about: 'О нас',
        contact: 'Свяжитесь с нами',
        language: 'Язык',
        english: 'Английский язык',
        russian: 'Русский язык',
        soho: 'Автомобильный клуб SOHO',
        title: 'У нас есть машина для тебя.',
        new_car: 'Новый автомобиль',
        new_title: 'Ниже приведены некоторые новейшие автомобили и специальные автомобили, которые мы часто предоставляем нашим клиентам. Мы надеемся, что вы найдете автомобиль ниже, который может вас заинтересовать.',
        view_all_cars: 'Посмотреть все автомобили',
        why_choose_us: 'Почему выбирают нас',
        home_tips: 'Именно поэтому многие клиенты выбирают наши услуги.',
        professionalism: 'Профессионализм',
        professionalism_tips: 'В команде CarPrice много преданных специалистов, которые полны решимости предоставить вам автомобиль, который лучше всего соответствует вашим потребностям.',
        support: '24 / 7 Поддержка',
        support_title: 'Наша команда готова предоставить вам профессиональную поддержку круглосуточно, чтобы помочь вам зарегистрировать автомобиль и лучше понять его.',
        affordability: 'Доступность',
        affordability_title: 'Если вы ищете доступный способ купить нужный автомобиль, CarPrice может быстро предоставить вам то, что вам нужно.',
        regular_checks: 'Периодические проверки',
        regular_checks_title: 'Все наши автомобили регулярно проверяются, чтобы предоставить вам наилучший выбор для решения повседневных транспортных задач с максимальной эффективностью.',
        pages: 'Страницы',
        popular_cars: 'Горячие автомобили',
        get_in_touch: 'Свяжитесь с нами',
        send_a_message: 'Отправить сообщение',
        vehicle_type: 'Тип транспортного средства',
        sedan: 'Автомобиль',
        mpv: 'МПВ',
        suv: 'Спортивный многоцелевой автомобиль',
        hatchback: 'Хетчбэк',
        price: 'Цены ($)',
        transmission: 'Трансмиссия',
        automatic: 'Автоматический',
        manual: 'Справочник',
        new: 'Новый',
        soho_choice: 'Выбор SOHO',
        buy_title: 'В слое улитки доломит, представляющий другие типы, является отторженным грибом. Это памятный момент.',
        find_your_car: 'Найти свой автомобиль',
        search: 'Поиск',
        search_by_car_name: 'Поиск по названию автомобиля',
        price_range: 'Диапазон цен ($)',
        about_title: 'SOHO Motor Club (Сохо Мотор Клуб) был основан в сентябре 1999 года.',
        about_content1: 'После многолетнего развития, под брендом "SOHO Motor Club" было открыто 21 авторизованный магазин, представляющих ведущие марки автомобилей среднего и высшего класса (такие как Audi, Cadillac, Volvo, Lexus, Buick, Chevrolet, Dongfeng Peugeot, Geely, Lynk & Co, GAC Aion, JMEV, Ora и другие), а также марки автомобилей собственного производства и новых энергий (Tesla, NIO, Jinkang и др.), обеспечивая продажу и обслуживание клиентов. Была создана бизнес-система онлайн-платформы, объединяющая "автомобильные выставки, консультации, обслуживание послепродажного обслуживания, автомобильные запчасти", предоставляющая клиентам надежные и удобные услуги по продаже автомобилей и функциональные услуги платформы.',
        about_content2: 'Одновременно с укреплением продаж автомобилей и функциональных услуг, "SOHO Motor Club" активно расширяет рынок дополнительных услуг по автомобилям. Бизнес включает в себя продажу подержанных автомобилей, сеть быстрого ремонта, агентство по запчастям, автомобильный бутик, автомобильные финансы и т.д., формируя полный цепочку бизнеса по продажам автомобилей, обеспечивая клиентам полный цикл управления ценностями и полный жизненный цикл.',
        about_content3: '"SOHO Motor Club" всегда будет придерживаться ценностей "Уважение к рынку (Клиент всегда на первом месте), Инновации и открытость (Открытость и инновации), Начинать всегда с нуля (Начать с первого дня), Видеть в долгосрочной перспективе (Думать крупно)", полностью используя рыночные ресурсы, постоянно оптимизируя и корректируя структуру продажи автомобилей и бизнеса, постоянно повышая качество продаж автомобилей, функциональных услуг платформы и сервисов по дополнительным услугам по автомобилям.',
        read: 'Читать далее',
        name: 'Имя',
        phone: 'Телефон',
        email: 'Электронная почта',
        message: 'Сообщение',
        send: 'Отправить',
        loading: 'Загрузка...',
        gallery: 'Галерея',
        customer: 'Обслуживание клиентов',
        thank: 'Большое спасибо.',
    },
    en: {
        home: 'HOME',
        buy: 'BUY',
        about: 'ABOUT US',
        contact: 'CONTACT US',
        language: 'LANGUAGE',
        english: 'English',
        russian: 'Russian',
        soho: 'SOHO Motor Club',
        title: 'We have the right auto for you',
        new_car: 'New cars',
        new_title: 'Here are some of our newest cars and featured vehicles that we regularly offer our customers. We hope you will find a car that might interest you below.',
        view_all_cars: 'View all cars',
        why_choose_us: 'Why choose us',
        home_tips: 'Here’s why lots of clients choose our services.',
        professionalism : 'Professionalism',
        professionalism_tips: 'CarPrice’s team has a lot of dedicated experts that are determined to give you a car that fits your needs the most.',
        support: 'Support',
        support_title: 'Our team is always ready to help you 24/7 professional support with registering your vehicle and getting to know it better.',
        affordability: 'Affordability',
        affordability_title: 'If you are looking for an affordable way to buy a desired car, CarPrice can provide you with what you need in no time.',
        regular_checks: 'Regular Checks',
        regular_checks_title: 'All our cars are regularly inspected to offer you the best options for solving the daily transportation tasks as efficiently as possible.',
        pages: 'Pages',
        popular_cars: 'Popular Cars',
        get_in_touch: 'Get in Touch',
        send_a_message: 'Send a Message',
        vehicle_type: 'Vehicle Type',
        sedan: 'Sedan',
        mpv: 'MPV',
        suv: 'SUV',
        hatchback: 'Hatchback',
        price: 'Price($)',
        transmission: 'Transmission',
        automatic: 'Automatic',
        manual: 'Manual',
        new: 'NEW',
        soho_choice: 'SOHO CHOICE',
        buy_title: 'Duis aute irure dolor in reprehenderit in voluptate velit esse cillum dolore eu fugiat nulla pariatur. Duis aute irure dolor in reprehenderit.',
        find_your_car: 'Find Your Car',
        search: 'Search',
        search_by_car_name: 'Search by Car Name',
        price_range: 'Price Range ($)',
        about_title: 'SOHO Motor Club was established in September 1999.',
        about_content1: 'After years of development, the Soho Motor Club brand has opened 21 authorized stores, representing mid to high end cars (such as Audi, Cadillac, Volvo, Lexus, Buick, Chevrolet, Dongfeng Peugeot, Geely, Lynk&Co, GAC Aion, JMEV, ORA, etc.) as well as self-produced and new energy vehicle brands (Tesla, NIO, Jinkang, etc.), providing sales and customer service. We have established an online platform business system that combines "automobile exhibition, consultation, after-sales service, and automobile parts" to provide customers with reliable and convenient automobile sales services and platform functional services.',
        about_content2: 'While strengthening automobile sales and functional services, SOHO Automobile Club is actively expanding the automobile auxiliary service market. Our business includes second-hand car sales, fast repair network, spare parts agency, automotive boutique, automotive finance, etc. Form a complete automotive sales business chain, providing customers with a complete value management cycle and a complete lifecycle.',
        about_content3: 'SOHO Automotive Club will always adhere to the values of "respecting the market (customer first), innovation and openness (open innovation), starting from scratch (from the first day), and looking at the long run (big thinking)", fully utilizing market resources, and continuously optimizing and adjusting automotive sales and business structure. Continuously improving the quality of automobile sales, functional platform services, and automobile ancillary services.',
        read: 'READ MORE',
        name: 'Name',
        phone: 'Phone',
        email: 'E-mail',
        message: 'Message',
        send: 'Send',
        loading: 'Loading...',
        gallery: 'Gallery',
        customer: 'Customer Service',
        thank: 'Thank You!',
    },
};

// 创建国际化实例
const i18n = createI18n({
    locale: 'en', // 设置默认语言为英文
    messages,
});

export default i18n;
