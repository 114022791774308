<template>
    <div>
      <AppHeader ref="appHeader" >
        <template v-slot:bg>
            <div class="page-header-1-figure m-parallax-figure">
            <div class="page-header-1-image m-parallax-image" :style="{ backgroundImage: `url(${car.image})` }"></div>
            </div>
        </template>
        <template v-slot:customFormItem>
            <section class="breadcrumbs-custom">
            <div class="breadcrumbs-custom-inner">
                <div class="container">
                <div class="breadcrumbs-custom-main m-parallax-content">
                    <h2 class="breadcrumbs-custom-title">{{ car.name }}</h2>
                    <!-- <p>Duis aute irure dolor in reprehenderit in voluptate velit esse cillum dolore eu fugiat nulla pariatur. Duis aute irure dolor in reprehenderit.</p> -->
                </div>
                </div>
            </div>
            </section>
        </template>
        <template v-slot:sectionItem>
            <section class="section section-md">
                <div class="container">
                <div class="row row-50">
                    <div class="col-lg-8">
                    <div class="inset-1">
                        <article class="post-info">
                        <h3 class="post-info-title">{{ car.name }}</h3>
                        <div class="post-info-details">
                            <p class="post-info-price">{{ car.price }}</p>
                            <div>
                            <ul class="list-inline list-inline-xs">
                                <li>
                                <div class="post-info-description"><span class="icon icon-lg mdi mdi-speedometer"></span><span class="text-gray-2-60">{{ car.mileage }}</span></div>
                                </li>
                                <li>
                                <div class="post-info-description"><span class="icon icon-lg mdi mdi-settings"></span><span class="text-gray-2-60">automatic</span></div>
                                </li>
                                <li>
                                <div class="post-info-description"><span class="icon icon-lg mdi mdi-map-marker"></span><span class="text-gray-2-60">{{ car.location }}</span></div>
                                </li>
                            </ul>
                            </div>
                        </div>
                        <!-- <p>{{ car.description1 }}</p>
                        <p>{{ car.description2 }}</p> -->
                        <div class="mt-20">
                            <img :src="car.image" alt="" width="714" height="346"/>
                        </div>
                        <table class="post-info-table w-100">
                            <tbody>
                                <tr>
                                <td>Specifications</td>
                                <td>
                                    <ul class="list-marked">
                                    <li>{{ car.manufacturer }}</li>
                                    <li>{{ car.type_of_energy }}</li>
                                    <li>{{ car.transmission }}</li>
                                    <li>{{ car.engine }}</li>
                                    </ul>
                                </td>
                                </tr>
                                <tr>
                                <td>Features</td>
                                <td>
                                    <ul class="list-marked">
                                    <li>{{car.driving_mode}}</li>
                                    <li>{{car.front_rear_brake_type}}</li>
                                    <li>{{car.parking_brake_type}}</li>
                                    </ul>
                                </td>
                                </tr>
                            </tbody>
                            </table>
                        <h3 class="mt-30 mt-lg-50 mt-xl-80">{{ $t('gallery') }}</h3>
                        <!-- <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit. Donec posuere metus et tortor pulvinar venenatis. Aliquam erat volutpat. Nam ultrices semper felis, at laoreet metus</p> -->
                        <div class="row row-6 row-x-6" data-lightgallery="group">
                            <div class="col-4" v-for="(image, index) in car.images" :key="index" style="background-color: black; display: flex; align-items: center; justify-content: center;">
                                <el-image
                                style="width: 355;height: 359;"
                                :src="image"
                                :preview-src-list="[image]">
                                </el-image>
                            </div>
                        </div>
                        </article>
                    </div>
                    </div>
                    <div class="col-lg-4">
                    <article class="box-4 box-profile">
                        <div class="box-4-inner">
                        <div class="box-profile-img"><img src="images/single-car-profile-91x91.jpg" alt="" width="91" height="91"/>
                        </div>
                        <h3 class="box-profile-title">Customer Service</h3>
                        <ul class="list-xs mt-20">
                            <li><a class="link-gray-500" href="tel:#">1-800-346-6277</a></li>
                            <li><a class="link-gray-500" href="mailto:#">info@demolink.org</a></li>
                        </ul>  <router-link class="button button-lg button-primary-color-dark" to="/contact">{{  $t('get_in_touch') }}</router-link>

                        </div>
                    </article>
                    </div>
                </div>
                </div>
            </section>
        </template>
        </AppHeader>
      <!-- 你的其他页面内容 -->
      <AppFooter />
    </div>
  </template>
  
  <script>
  import AppHeader from '../components/AppHeader.vue';
  import AppFooter from '../components/AppFooter.vue';
  import axios from 'axios';

  export default {
    name: "Car",
    components: {
      AppHeader,
      AppFooter
    },
    data() {
        return {
            car: {
                name: '',
                image: '',
                description1: '',
                description2: '',
                location: '',
                price: '',
                mileage: '',
                manufacturer: '',
                type_of_energy: '',
                transmission: '',
                engine: '',
                driving_mode: '',
                front_rear_brake_type: '',
                parking_brake_type: '',
                images: []
            }
        };
    },
    methods: {
         // 获取指定页码的汽车数据
         async fetchCars(id) {
            axios.get(`/carDetail/` + id).then((response) => {
                this.car.name = response.data.name;
                this.car.image = response.data.oss_file;
                this.car.description1 = response.data.description1;
                this.car.description2 = response.data.description2;
                this.car.location = response.data.production_area;
                this.car.price = response.data.price;
                this.car.mileage = response.data.electric_range;
                this.car.images = response.data.images;
                this.car.manufacturer = response.data.manufacturer;
                this.car.type_of_energy = response.data.type_of_energy;
                this.car.engine = response.data.engine;
                this.car.transmission = response.data.transmission;
                this.car.driving_mode = response.data.driving_mode;
                this.car.front_rear_brake_type = response.data.front_rear_brake_type;
                this.car.parking_brake_type = response.data.parking_brake_type;
                this.$refs.appHeader.handlePageTransition();
            });
        },
    },
    mounted() {
        let carId = this.$route.query.id;  // 获取 URL 中的 id 参数
        this.fetchCars(carId);
    },
  };
  </script>
  