<template>
    <div>
      <AppHeader ref="appHeader">
          <template v-slot:bg>
              <div class="page-header-1-figure m-parallax-figure">
              <div class="page-header-1-image m-parallax-image" style="background-image: url(images/bg-image-4.jpg);"></div>
              </div>
          </template>
          <template v-slot:customFormItem>
            <section class="breadcrumbs-custom">
            <div class="breadcrumbs-custom-inner">
                <div class="container">
                <div class="breadcrumbs-custom-main m-parallax-content">
                    <h2 class="breadcrumbs-custom-title">{{ $t('contact') }}</h2>
                    <p>{{ $t('buy_title') }}</p>
                </div>
                </div>
            </div>
            </section>
          </template>
          <template v-slot:sectionItem>
               <!-- Addresses-->
                <section class="section section-md bg-gray-100"> 
                    <div class="container">
                    <div class="row row-30">
                        <div class="col-sm-6 col-lg-4">
                        <!-- Box 1-->
                        <address class="box-1">
                            <p class="heading-4 box-1-title">New York office</p>
                            <p class="box-1-address heading-6">2260 Oak St, New York, NY 13420</p>
                            <p class="box-1-tel heading-3"><a href="tel:#">1-800-346-6277</a></p>
                        </address>
                        </div>
                        <div class="col-sm-6 col-lg-4">
                        <!-- Box 1-->
                        <address class="box-1">
                            <p class="heading-4 box-1-title">Seattle office</p>
                            <p class="box-1-address heading-6">2880  University St, Seattle, WA 98155</p>
                            <p class="box-1-tel heading-3"><a href="tel:#">1-780-964-1989</a></p>
                        </address>
                        </div>
                        <div class="col-sm-6 col-lg-4">
                        <!-- Box 1-->
                        <address class="box-1">
                            <p class="heading-4 box-1-title">Los Angeles office</p>
                            <p class="box-1-address heading-6">3126  Purple Dr, Los Angeles, CA 90017</p>
                            <p class="box-1-tel heading-3"><a href="tel:#">61-02-6156-0706</a></p>
                        </address>
                        </div>
                    </div>
                    </div>
                </section>
                <!-- Get in Touch-->
                <section class="section section-lg bg-default">
                    <div class="container">
                    <h3>{{ $t('get_in_touch') }}</h3>
                    <!-- RD Mailform-->
                    <form class="rd-form rd-mailform form-lg" @submit.prevent="send">
                        <div class="row row-30">
                        <div class="col-lg-4">
                            <div class="form-wrap form-wrap-icon">
                            <input class="form-input" v-model="form.name" type="text" id="contact-name" name="name" required />
                            <label class="form-label" for="contact-name">{{$t('name')}}</label>
                            <div class="icon form-icon mdi mdi-account-outline text-primary"></div>
                            </div>
                        </div>

                        <div class="col-lg-4">
                            <div class="form-wrap form-wrap-icon">
                            <input class="form-input" v-model="form.phone" type="text" id="contact-phone" name="phone" required />
                            <label class="form-label" for="contact-phone">{{$t('phone')}}</label>
                            <div class="icon form-icon mdi mdi-phone text-primary"></div>
                            </div>
                        </div>

                        <div class="col-lg-4">
                            <div class="form-wrap form-wrap-icon">
                            <input class="form-input" v-model="form.email" type="email" id="contact-email" name="email" required />
                            <label class="form-label" for="contact-email">{{$t('email')}}</label>
                            <div class="icon form-icon mdi mdi-email-outline text-primary"></div>
                            </div>
                        </div>

                        <div class="col-12">
                            <div class="form-wrap form-wrap-icon">
                            <label class="form-label" for="contact-message">{{$t('message')}}</label>
                            <textarea class="form-input" v-model="form.message" id="contact-message" name="message" required></textarea>
                            <div class="icon form-icon mdi mdi-message-outline text-primary"></div>
                            </div>
                        </div>
                        </div>

                        <div class="form-wrap form-wrap-button">
                        <button class="button button-lg button-primary" type="submit" v-loading="loading">{{$t('send')}}</button>
                        </div>
                    </form>
                    </div>
                </section>
          </template>
        </AppHeader>
  
      <AppFooter />
    </div>
  </template>
  
  <script>
  import AppHeader from '../components/AppHeader.vue';
  import AppFooter from '../components/AppFooter.vue';
  import axios from 'axios';

  export default {
    data() {
        return {
            form: {
                name: '',
                phone: '',
                email: '',
                message: ''
            },
            loading: false,
        };
    },
    components: {
      AppHeader,
      AppFooter
    },
    mounted(){
        this.$refs.appHeader.handlePageTransition();
    },
    methods: {
        async send() {
            this.loading = true;
            try {
                console.log("Form Data: ", this.form);
                await axios.post('/sendForm', this.form);
                this.loading = false;
                this.$router.push('/success');
            } catch (err) {
                console.error('提交失败:', err);
            } finally {
                this.loading = false; // 请求完成后更新 loading 状态
            }
        },
    }
  };
  </script>
  