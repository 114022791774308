<template>
      <footer class="section footer-modern bg-gray-800 context-dark">
        <div class="footer-modern-main">
          <div class="container">
            <div class="row row-30 justify-content-md-between">
              <div class="col-sm-5 col-md-3 col-lg-3">
                <p class="footer-modern-title">{{ $t('pages') }}</p>
                <div class="footer-modern-item-block">
                  <ul class="list list-1">
                    <li><router-link to="/about">{{ $t('about') }}</router-link></li>

                    <!-- <li><a href="our-team.html">Our Team</a></li>
                    <li><a href="testimonials.html">Testimonials</a></li>
                    <li><a href="#">Blog</a></li> -->
                  </ul>
                </div>
              </div>
              <div class="col-sm-7 col-md-5 col-lg-4">
                <p class="footer-modern-title">{{ $t('popular_cars') }}</p>
                <div class="footer-modern-item-block" style="max-width: 300px;">
                  <div class="row row-13">
                    <div class="col-6">
                      <ul class="list list-1">
                        <li><router-link to="/buy?brand=BYD">BYD</router-link></li>
                        <li><router-link to="/buy?brand=Lixiang">Lixiang</router-link></li>
                        <li><router-link to="/buy?brand=Geely">Geely</router-link></li>
                        <li><router-link to="/buy?brand=ChangAn">ChangAn</router-link></li>
                        <li><router-link to="/buy?brand=LinkCo">LinkCo</router-link></li>
                      </ul>
                    </div>
                    <div class="col-6">
                      <ul class="list list-1">
                        <li><router-link to="/buy?brand=AITO">AITO</router-link></li>
                        <li><router-link to="/buy?brand=ZEEKR">ZEEKR</router-link></li>
                        <li><router-link to="/buy?brand=Tank">Tank</router-link></li>
                        <li><router-link to="/buy?brand=NIO">NIO</router-link></li>
                        <li><router-link to="/buy?brand=GACMOTOR">GACMOTOR</router-link></li>
                      </ul>
                    </div>
                  </div>
                </div>
              </div>
              <div class="col-md-3">
                <p class="footer-modern-title">{{ $t('get_in_touch') }}</p>
                <div class="footer-modern-item-block">
                  <ul class="list list-1">
                    <li><router-link to="/contact">{{  $t('contact') }}</router-link></li>
                    <li><router-link to="/contact">{{  $t('send_a_message') }}</router-link></li>
                  </ul>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div class="footer-modern-aside">
          <div class="container">
            <div class="footer-modern-aside-layout">
              <router-link class="brand" to="/home">
                <img class="brand-logo-dark" src="images/1729757984246_1.png" alt="" width="141" height="29"/><img class="brand-logo-light" src="images/1729757984246_1.png" alt="" width="141" height="29"/>
              </router-link>
              <p class="rights"><span>&copy;&nbsp;</span><span class="copyright-year"></span><span>&nbsp;</span><span>SOHO MOTOR CLUB</span><span>. All rights reserved.&nbsp;Privacy Policy</span></p>
              <div>
                <div class="group group-sm"><a class="link-1 link-1-social link-social-footer icon mdi mdi-instagram" href="#"></a><a class="link-1 link-1-social link-social-footer icon mdi mdi-facebook" href="#"></a><a class="link-1 link-1-social link-social-footer icon mdi mdi-youtube-play" href="#"></a></div>
              </div>
            </div>
          </div>
        </div>
      </footer>
      
  </template>
  
  <script>
  export default {
    name: "AppFooter"
  }
  </script>
  
  <style scoped>
  .footer {
    text-align: center;
    padding: 20px;
    background-color: #f8f9fa;
  }
  </style>
  