<template>
  <div>
    <!-- 你的其他页面内容 -->
    <HomeHeader ref="appHeader">
      <template v-slot:filter></template>
      <template v-slot:search>
        <div class="jumbotron-2-content-inner">
              <form class="rd-form rd-mailform form-lg form-2">
                <div class="form-wrap-outer wow fadeIn">
                  <div class="form-wrap form-wrap-icon form-wrap-flex">
                  <!-- 使用 v-model 绑定 -->
                  <el-select v-model="searchItem.vehicleType" :placeholder="$t('vehicle_type')" class="form-input" id="vehicle_model" name="vehicle_model">
                    <el-option :label="$t('sedan')" value="SEDAN"></el-option>
                    <el-option :label="$t('mpv')" value="MPV"></el-option>
                    <el-option :label="$t('suv')" value="SUV"></el-option>
                    <el-option :label="$t('hatchback')" value="Hatchback"></el-option>
                  </el-select>
                  <div class="icon form-icon mdi mdi-car"></div>
                </div>

                </div>

                <div class="form-wrap-outer wow fadeIn">
                  <div class="form-wrap form-wrap-icon">
                    <el-select v-model="searchItem.priceRange" :placeholder="$t('price')" class="form-input" id="price" name="price">
                      <el-option label="0-15000" value="1"></el-option>
                      <el-option label="15000-20000" value="2"></el-option>
                      <el-option label="20000-30000" value="3"></el-option>
                      <el-option label="30000-50000" value="4"></el-option>
                      <el-option label="50000-100000" value="5"></el-option>
                    </el-select>
                    <div class="icon form-icon mdi mdi-map-marker"></div>
                  </div>
                </div>

                <div class="form-wrap-outer wow fadeIn">
                  <div class="form-wrap form-wrap-icon">
                    <el-select v-model="searchItem.transmission" :placeholder="$t('transmission')" class="form-input" id="transmission" name="transmission">
                      <el-option :label="$t('automatic')" value="Automatic"></el-option>
                      <el-option :label="$t('manual')" value="Manual"></el-option>
                    </el-select>
                    <div class="icon form-icon mdi mdi-settings"></div>
                  </div>
                </div>

                <!-- <div class="form-wrap-outer wow fadeIn">
                  <div class="form-wrap form-wrap-icon">
                    <select class="form-input" id="mileage" name="gender"  data-placeholder="Mileage">
                      <option label="placeholder"></option>
                      <option>100 mi</option>
                      <option>200 mi</option>
                      <option>300 mi</option>
                    </select>
                    <div class="icon form-icon mdi mdi-speedometer"></div>
                  </div>
                </div> -->
                <div class="form-wrap-outer form-wrap-outer-button wow fadeIn" data-wow-delay=".15s">
                  <div class="form-button">
                    <!-- 使用 @click 绑定点击事件 -->
                    <button class="button button-lg button-icon button-2 button-yellow-custom" @click.prevent="searchCars" aria-label="Search">
                      <span class="icon mdi mdi-magnify"></span>
                    </button>
                  </div>
                </div>

              </form>
            </div>
      </template>
      <template v-slot:brand>
        <div class="container">
              <div class="box-9-group-outer">
                <div class="box-9-group">
                  <div v-for="(brand, index) in carBrands" :key="index" class="box-9-item wow fadeIn" data-wow-delay=".1s">
                    <router-link :to="`/buy?brand=${brand.name}`" class="box-9-link" data-class="tooltip-light" data-toggle="tooltip" data-placement="top" :title="brand.name">
                      <div class="box-9-link-inner">
                        <img :src="brand.image" alt="" width="48" height="48"/>
                      </div>
                      <p class="box-9-title">{{ brand.name }}</p>  
                    </router-link>
                  </div>
                </div>
              </div>
        </div>
      </template>
      <template v-slot:table>
        <div class="row row-30 row-xl-40 mt-50">
          <div 
            v-for="car in cars" 
            :key="car.id" 
            class="col-sm-6 col-lg-4 wow fadeIn" 
            data-wow-delay=".025s"
          >
            <article class="tour-classic">
              <div class="tour-classic-media">
                <router-link :to="`/car?id=${car.id}`" class="tour-classic-figure">
                  <img :src="car.image" class="tour-classic-image" alt="" width="369" height="248"/>
                </router-link>
                <div class="tour-classic-pricing" v-if="car.label">
                  <p class="tour-classic-price tour-classic-price-new">{{ $t('new') }}</p>
                </div>
              </div>
              <div class="tour-classic-body">
                <h4 class="tour-classic-title"><a :href="`/car?id=${car.id}`">{{ car.name }}</a></h4>
                <p class="tour-classic-caption">{{ car.price }}</p>
                <div class="tour-classic-footer"></div>
              </div>
            </article>
          </div>
        </div>
        <!-- <div class="row row-30 row-xl-40 mt-50">
          <div class="col-sm-6 col-lg-4 wow fadeIn">
            <article class="tour-classic">
              <div class="tour-classic-media">
                <router-link to="/car" class="tour-classic-figure">
                  <img class="tour-classic-image" src="images/new-car-1-369x248.jpg" alt="" width="369" height="248"/>
                </router-link>
                <div class="tour-classic-pricing">
                  <p class="tour-classic-price tour-classic-price-new">NEW</p>
                </div>
              </div>
              <div class="tour-classic-body">
                <h4 class="tour-classic-title"><a href="single-car-page.html">Chevrolet Camaro SS</a></h4>
                <p class="tour-classic-caption">$18,000</p>
                <div class="tour-classic-footer"></div>
              </div>
            </article>
          </div>
          <div class="col-sm-6 col-lg-4 wow fadeIn" data-wow-delay=".025s">
            <article class="tour-classic">
              <div class="tour-classic-media">
                <router-link to="/car" class="tour-classic-figure">
                  <img class="tour-classic-image" src="images/new-car-2-369x248.jpg" alt="" width="369" height="248"/>
                </router-link>
                <div class="tour-classic-pricing">
                  <p class="tour-classic-price tour-classic-price-new">BEST PRICE</p>
                </div>
              </div>
              <div class="tour-classic-body">
                <h4 class="tour-classic-title"><a href="single-car-page.html">Volkswagen T1</a></h4>
                <p class="tour-classic-caption">$15,000</p>
                <div class="tour-classic-footer">
                  
                </div>
              </div>
            </article>
          </div>
          <div class="col-sm-6 col-lg-4 wow fadeIn" data-wow-delay=".05s">
            <article class="tour-classic">
              <div class="tour-classic-media">
                <router-link to="/car" class="tour-classic-figure">
                  <img class="tour-classic-image" src="images/new-car-3-369x248.jpg" alt="" width="369" height="248"/>
                </router-link>
              </div>
              <div class="tour-classic-body">
                <h4 class="tour-classic-title"><a href="single-car-page.html">Ford Expedition</a></h4>
                <p class="tour-classic-caption">$28,000</p>
                <div class="tour-classic-footer"></div>
              </div>
            </article>
          </div>
          <div class="col-sm-6 col-lg-4 wow fadeIn">
            <article class="tour-classic">
              <div class="tour-classic-media">
                <router-link to="/car" class="tour-classic-figure">
                  <img class="tour-classic-image" src="images/new-car-4-369x248.jpg" alt="" width="369" height="248"/>
                </router-link>
              </div>
              <div class="tour-classic-body">
                <h4 class="tour-classic-title"><a href="single-car-page.html">Tesla Model S</a></h4>
                <p class="tour-classic-caption">$21,000</p>
                <div class="tour-classic-footer"></div>
              </div>
            </article>
          </div>
          <div class="col-sm-6 col-lg-4 wow fadeIn" data-wow-delay=".025s">
            <article class="tour-classic">
              <div class="tour-classic-media">
                <router-link to="/car" class="tour-classic-figure">
                  <img class="tour-classic-image" src="images/new-car-5-369x248.jpg" alt="" width="369" height="248"/>
                </router-link>
              </div>
              <div class="tour-classic-body">
                <h4 class="tour-classic-title"><a href="single-car-page.html">Audi TT Quattro</a></h4>
                <p class="tour-classic-caption">$19,000</p>
                <div class="tour-classic-footer"></div>
              </div>
            </article>
          </div>
          <div class="col-sm-6 col-lg-4 wow fadeIn" data-wow-delay=".05s">
            <article class="tour-classic">
              <div class="tour-classic-media">
                <router-link to="/car" class="tour-classic-figure">
                  <img class="tour-classic-image" src="images/new-car-6-369x248.jpg" alt="" width="369" height="248"/>
                </router-link>
              </div>
              <div class="tour-classic-body">
                <h4 class="tour-classic-title"><a href="single-car-page.html">Audi A3</a></h4>
                <p class="tour-classic-caption">$25,000</p>
                <div class="tour-classic-footer"></div>
              </div>
            </article>
          </div>
        </div> -->
      </template>
    </HomeHeader>
    <AppFooter />
  </div>
</template>

<script>
import AppFooter from '../components/AppFooter.vue';
import HomeHeader from '../components/HomeHeader.vue';
import axios from 'axios';

export default {
  components: {
    AppFooter,
    HomeHeader
  },
  data() {
    return {
      data: null,
      loading: true,
      error: null,
      cars: [],
      carBrands: [],
      searchItem: {
        vehicleType: '',      // 车辆类型
        priceRange: '',            // 价格区间
        transmission: ''      // 变速箱类型
      }
    };
  },
  created() {
    this.fetchCar(); // 在组件创建时发起请求
  },
  methods: {
    async fetchCar() {
      try {
        axios.get(`/cars`).then((response) => {
          this.cars = response.data.cars; 
          this.carBrands = response.data.brand;
          this.$refs.appHeader.handlePageTransition();
        });        
      } catch (err) {
        console.error('获取车辆数据失败:', err);
      } finally {
        this.loading = false; // 请求完成后更新 loading 状态
      }
    },
    searchCars() {
      console.log(this.searchItem);
      // 使用 Vue Router 进行页面跳转，并传递 searchItem 作为 query 参数
      this.$router.push({
        path: '/buy',
        query: { ...this.searchItem } // 将 searchItem 作为查询参数传递
      });
    }
  },
};
</script>

<style scoped>
/* 提高优先级 */
::v-deep .el-select__wrapper {
    box-shadow: 0 0 0 0px rgba(0, 0, 0, 0.25) inset !important;    /* 移除可能的阴影 */
}
::v-deep .el-select-dropdown__item.is-selected {
    color: #fcc12a;
    font-weight: bold;
}
</style>
